import React, { useState, useEffect } from 'react';
import { Card, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import { FaPaypal } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import './PayPalOAuth.css';

const PayPalOAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user_id') || '';
  
  const [config, setConfig] = useState({
    clientId: '',
    redirectUri: '',
    environment: 'sandbox'
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [authUrl, setAuthUrl] = useState('');
  
  useEffect(() => {
    // 設定ファイルから PayPal 設定を読み込む
    const checkCredentials = async () => {
      try {
        const response = await fetch('/AccountMGT/server/check-paypal-credentials.php');
        
        if (!response.ok) {
          throw new Error('認証情報の確認に失敗しました');
        }
        
        const data = await response.json();
        
        if (!data.success || !data.credentials.client_id) {
          // 認証情報がない場合は入力ページにリダイレクト
          navigate('/paypal-dev-auth');
          return;
        }
        
        // 認証情報を設定
        setConfig({
          clientId: data.credentials.client_id,
          redirectUri: data.credentials.redirect_uri,
          environment: data.credentials.environment
        });
        
        // 認証情報が読み込めたらPrepareAuthを実行
        setLoading(false);
      } catch (err) {
        console.error('認証情報確認エラー:', err);
        setError('PayPal認証情報の確認に失敗しました。入力画面に戻ります。');
        
        // エラーの場合は3秒後に入力ページにリダイレクト
        setTimeout(() => {
          navigate('/paypal-dev-auth');
        }, 3000);
      }
    };
    
    checkCredentials();
  }, [navigate]);
  
  // クライアントIDが設定されたら認証URLを準備
  useEffect(() => {
    const prepareAuth = async () => {
      if (!config.clientId) return;
      
      try {
        console.log('PayPal認証URL準備開始', {
          clientId: config.clientId,
          environment: config.environment
        });
        
        const response = await fetch('/AccountMGT/server/prepare-paypal-oauth.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            client_id: config.clientId,
            environment: config.environment,
            is_admin: false // 一般ユーザーフローであることを明示
          })
        });
        
        const data = await response.json();
        console.log('PayPal OAuth準備レスポンス:', data); // デバッグ用
        
        if (data.success) {
          console.log('PayPal認証URL取得成功:', data.auth_url);
          setAuthUrl(data.auth_url);
        } else {
          console.error('PayPal認証URL取得失敗:', data.error);
          setError(`認証URLの生成に失敗しました: ${data.error}`);
        }
      } catch (err) {
        console.error('PayPal OAuth準備エラー:', err);
        setError(`エラーが発生しました: ${err.message}`);
      }
    };
    
    if (config.clientId) {
      prepareAuth();
    }
  }, [config.clientId, config.environment]);

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-3">PayPal設定を読み込んでいます...</p>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow-sm paypal-oauth-card">
            <Card.Header className="bg-primary text-white text-center">
              <h2 className="mb-0">PayPal OAuth2 認証</h2>
            </Card.Header>
            <Card.Body className="text-center py-5">
              {error ? (
                <Alert variant="danger">{error}</Alert>
              ) : (
                <>
                  <p className="mb-4">PayPalと連携して、サブスクリプションを管理します。</p>
                  <p className="mb-4 text-muted small">PayPalアカウントに接続すると、以下の権限が許可されます：</p>
                  <ul className="text-start text-muted small mb-4">
                    <li>プロフィール情報の読み取り（名前、メールアドレス）</li>
                    <li>サブスクリプション情報の管理</li>
                    <li>Webhookイベントの受信</li>
                  </ul>
                  {userId && (
                    <Alert variant="info" className="mb-4">
                      ユーザーID: {userId} として連携します
                    </Alert>
                  )}
                  <div className="d-grid">
                    <Button 
                      href={authUrl} 
                      variant="primary" 
                      size="lg" 
                      className="paypal-btn"
                      disabled={!authUrl}
                    >
                      <FaPaypal className="me-2" />
                      PayPal にログイン
                    </Button>
                  </div>
                  {!authUrl && !error && (
                    <Alert variant="warning" className="mt-3">
                      認証URLを準備中です...
                    </Alert>
                  )}
                  <p className="mt-4 text-muted small">
                    環境: <span className="badge bg-secondary">{config.environment}</span>
                  </p>
                </>
              )}
            </Card.Body>
            <Card.Footer className="text-center text-muted">
              <small>問題が発生した場合は管理者にお問い合わせください</small>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayPalOAuth; 