import React from "react";
import styled from '@emotion/styled';
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { useNavigate, useLocation } from 'react-router-dom';
//import Header from "./Header";
//import Footer from "./Footer";
import Font from "../Style/font.css";
import ThanksIMG from "../images/thanks.svg";

const styles = {
  pcDrawer: {
    display:'block',
    '@media (min-width: 600px)': {
      display: 'block',
    }
  },
  main:{
    width:'100%',
    marginRight:'auto',
    marginLeft:'auto',
    height:'100%',
    textAlign:'center',
  },
  area:{
    display:'inline-block',
    position:'relative',
    verticalAlign:'top',
    width:'100%',
    backgroundColor:'#0F56A8',
    marginTop:'-64px',
  },
  ill_area:{

  },

  ill_image:{
    height:'302px',
    display: 'block',
    width:'100%',
  },
  thanks_msg_bold:{
    display: 'block',
    fontSize:'28px',
    fontWeight:'700',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#333333',
  },
  thanks_msg_regular:{
    display: 'block',
    fontSize:'20px',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#d9394e',
    fontWeight:'bold',
    opacity: '0.5',
  },

  button_area:{
    width:'100%',
  },

  detail_button:{
    backgroundColor:'#0F56A8',
    color:'#ffffff',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    fontFamily:'Noto Sans Japanese',
    textAlign:'center',
  },
  top_button:{
    backgroundColor:'#ffffff',
    color:'#0F56A8',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    textAlign:'center',
  },

  //テキストボックスの大きさ(中)
  text_width_120px:{
    width:'120px',
    margin:'0px'
  },
  text_width_150px:{
    width:'150px',
    margin:'0px'
  },
  text_width_170px:{
    width:'170px',
    margin:'0px'
  },
  text_width_280px:{
    width:'280px',
    margin:'0px'
  },
  text_width_300px:{
    width:'300px',
    margin:'0px'
  },
  //テキストボックスの大きさ(大)
  text_width_640px:{
    width:'640px',
    margin:'0px',
  },
  //テキストボックスの大きさ(中)
  text_picker_field:{
    width:'80px',
    margin:'0px',
  },
  //テキストボックスの大きさ(小)
  text_small_field:{
    width:'50px',
    margin:'0px'
  },

  //コンテンツ間の空間（横）
  marginLeft_16px:{
    marginLeft:'16px'
  },
  marginLeft_40px:{
    marginLeft:'40px'
  },
  //コンテンツ間の空間（縦）
  marginBottom_8px:{
    marginBottom:'8px',
  },
  marginBottom_16px:{
    marginBottom:'16px',
  },
  marginBottom_24px:{
    marginBottom:'24px',
  },
  marginBottom_32px:{
    marginBottom:'32px',
  },
  marginBottom_64px:{
    marginBottom:'64px',
  },
  marginBottom_96px:{
    marginBottom:'96px',
  },
};

/*
  プライバシーポリシー
*/
function ThanksPaidHachiFormWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <ThanksPaidHachiForm {...props} navigate={navigate} location={location} />;
}

class ThanksPaidHachiForm extends React.Component {
  render() {
    const { classes, navigate, location } = this.props;
    return (
      <div className={Font.body}>
        <div className={classes.pcDrawer}>
          <div className={classes.main}>
            <div className={classes.marginBottom_64px} />
            <div className={classes.ill_area}>
              <div className={classes.marginBottom_64px} />
              <img
                src={ThanksIMG}
                className={classes.ill_image}
                alt="Thanks"
              />
              <div className={classes.marginBottom_64px} />
              <div className={classes.thanks_msg_bold}>
                申請ありがとうございます。
              </div>
              <div className={classes.marginBottom_16px} />
              <div className={classes.thanks_msg_regular}>
                メールにてEAをお送りいたしました。
              </div>
              <div className={classes.thanks_msg_regular}>
                もし届かない場合は、迷惑メールフォルダに入っていないかご確認下さい。
              </div>              
              <div className={classes.marginBottom_64px} />
              <div className={classes.marginBottom_32px} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleOnclick() {
    const { navigate, location } = this.props;
    navigate('/somewhere');
  }
}

ThanksPaidHachiForm.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThanksPaidHachiFormWrapper);
