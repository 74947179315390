import React from "react";
import styled from '@emotion/styled';
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { useNavigate, useLocation } from 'react-router-dom';
import Font from "../Style/font.css";
import ThanksIMG from "../images/thanks.svg";
import Button from '@mui/material/Button';

const styles = {
  pcDrawer: {
    display: 'block',
    '@media (min-width: 600px)': {
      display: 'block',
    }
  },
  main: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  ill_image: {
    height: '302px',
    display: 'block',
    width: '100%',
  },
  thanks_msg_bold: {
    display: 'block',
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#333333',
  },
  thanks_msg_regular: {
    display: 'block',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#000000',
    opacity: '0.5',
  },
  button_area: {
    width: '100%',
  },
};

const StyledButton = styled(Button)({
  '&.detail-button': {
    backgroundColor: '#0F56A8',
    color: '#ffffff',
    width: '200px',
    fontSize: '14px',
    borderColor: '#0f56A8',
    borderStyle: 'none',
    fontFamily: 'Noto Sans Japanese',
    textAlign: 'center',
  },
});

// ✅ フックをクラスコンポーネントに渡すラッパー
function ThanksLotToolWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <ThanksLotTool {...props} navigate={navigate} location={location} />;
}

class ThanksLotTool extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnclick = this.handleOnclick.bind(this);
  }

  handleOnclick() {
    const { navigate, location } = this.props;

    // URLパラメータ取得
    const params = new URLSearchParams(location.search);
    const id = params.get('id') || '';
    const extra = params.get('extra') || '';

    if (!id) {
      navigate('/hk_lottool');
    } else {
      navigate(`/hk_lottool?id=${id}&extra=${extra}`);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={Font.body}>
        <div className={classes.pcDrawer}>
          <div className={classes.main}>
            <img
              src={ThanksIMG}
              className={classes.ill_image}
              alt="Thanks"
            />
            <div className={classes.thanks_msg_bold}>
              申請ありがとうございます。
            </div>
            <div className={classes.thanks_msg_regular}>
              メールを送信致しました。
            </div>
            <div className={classes.thanks_msg_regular}>
              迷惑メールに入っている可能性もございます。
            </div>
            <div className={classes.thanks_msg_regular}>
              ご確認の程よろしくお願い致します。
            </div>
            <div className={classes.button_area}>
              <StyledButton
                variant="contained"
                className="detail-button"
                onClick={this.handleOnclick}
              >
                申請画面に戻る
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ThanksLotTool.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired, // ✅ navigateを必須に
  location: PropTypes.object.isRequired, // ✅ locationも必須に
};

export default withStyles(styles)(ThanksLotToolWrapper);