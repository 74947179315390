import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // この行が必要
import App from './App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import reportWebVitals from './reportWebVitals';

// ↓ 本番ビルドで console.log を無効化
process.env.NODE_ENV !== "development" && (console.log = () => {});

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAP4-lGH0V40vC4Zn-5-abgzypDm3C4Yas",
  authDomain: "devea-77052.firebaseapp.com",
  projectId: "devea-77052",
  storageBucket: "devea-77052.firebasestorage.app",
  messagingSenderId: "775122064108",
  appId: "1:775122064108:web:34447268233e1e83ed4bc9",
  measurementId: "G-D3DN8RLLS8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// Service Workerの登録
navigator.serviceWorker
    .register('/firebase-messaging-sw.js') // publicフォルダ内のService Workerを登録
    .then(function(registration) {
        console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(function(error) {
        console.error('Service Worker registration failed:', error);
    });

reportWebVitals();