import React, { useState, useEffect } from 'react';
import './MailFormFromHakaseEA.css';

const MailFormFromHakaseEA = () => {
  const [csvData, setCsvData] = useState([]);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [preview, setPreview] = useState(false);
  const [showCsvPreview, setShowCsvPreview] = useState(false);
  const [csvPreviewLimit, setCsvPreviewLimit] = useState(10);
  const [genre, setGenre] = useState('');
  // 作成者コードは固定値として1を使用
  const createrCode = 1;
  
  // バッチ送信の設定
  const [batchSize, setBatchSize] = useState(100); // 1バッチあたりの送信数
  const [batchInterval, setBatchInterval] = useState(20); // バッチ間の間隔（分）
  const [useBatchSending, setUseBatchSending] = useState(false); // バッチ送信を使用するかどうか
  const [batchProgress, setBatchProgress] = useState({ current: 0, total: 0, nextBatch: null });
  const [batchSendingActive, setBatchSendingActive] = useState(false);
  const [remainingEmails, setRemainingEmails] = useState([]);
  
  // メール設定
  const [fromEmail, setFromEmail] = useState('');
  const [fromName, setFromName] = useState('');
  const [replyTo, setReplyTo] = useState('');
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  // コンポーネントマウント時にメール設定を取得
  useEffect(() => {
    fetchMailConfig();
  }, []);

  // バッチ送信のタイマー制御
  useEffect(() => {
    let timer = null;
    
    if (batchSendingActive && remainingEmails.length > 0 && batchProgress.nextBatch) {
      const now = new Date();
      const nextBatchTime = new Date(batchProgress.nextBatch);
      const timeUntilNextBatch = nextBatchTime - now;
      
      if (timeUntilNextBatch > 0) {
        // 次のバッチまでのタイマーを設定
        timer = setTimeout(() => {
          sendNextBatch();
        }, timeUntilNextBatch);
        
        // 残り時間を更新するためのタイマー
        const updateTimer = setInterval(() => {
          const currentTime = new Date();
          const remainingTime = nextBatchTime - currentTime;
          
          if (remainingTime <= 0) {
            clearInterval(updateTimer);
          } else {
            const minutes = Math.floor(remainingTime / 60000);
            const seconds = Math.floor((remainingTime % 60000) / 1000);
            setMessage(`次のバッチを送信中...(${batchProgress.current}/${batchProgress.total} 完了) 次のバッチまで: ${minutes}分${seconds}秒`);
          }
        }, 1000);
        
        return () => {
          clearTimeout(timer);
          clearInterval(updateTimer);
        };
      } else {
        // 次のバッチ時間が過ぎている場合、すぐに送信
        sendNextBatch();
      }
    }
    
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [batchSendingActive, remainingEmails, batchProgress]);

  // メール設定を取得
  const fetchMailConfig = async () => {
    try {
      var baseUrl = window.location.origin;
      const response = await fetch(baseUrl+'/AccountMGT/server/get-mail-config.php');
      const data = await response.json();
      
      if (data.success) {
        setFromEmail(data.data.from_email);
        setFromName(data.data.from_name);
        setReplyTo(data.data.reply_to);
      } else {
        setMessage('メール設定の取得に失敗しました');
      }
    } catch (error) {
      console.error('メール設定の取得エラー:', error);
      setMessage('メール設定の取得中にエラーが発生しました');
    } finally {
      setIsConfigLoading(false);
    }
  };

  // CSVファイルをアップロードして読み込む処理
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvText = event.target.result;
        // 改行で行を分割
        const rows = csvText.split(/\r?\n/);
        const emails = [];
        
        // 各行を処理
        rows.forEach(row => {
          if (!row.trim()) return; // 空行はスキップ
          
          // カンマで分割
          const columns = row.split(',');
          
          // 各列をチェックしてメールアドレスを抽出
          columns.forEach(column => {
            const trimmedColumn = column.trim();
            // メールアドレスの簡易バリデーション
            if (trimmedColumn && trimmedColumn.includes('@') && 
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedColumn)) {
              emails.push({ email: trimmedColumn });
            }
          });
        });
        
        setCsvData(emails);
        setMessage(`${emails.length}件のメールアドレスが読み込まれました` + 
                  (emails.length > batchSize && !useBatchSending ? 
                    `（件数が多いため、バッチ送信の利用をおすすめします）` : ''));
        
        // 件数が多い場合は自動的にバッチ送信を有効化
        if (emails.length > 100 && !useBatchSending) {
          setUseBatchSending(true);
        }
        
        // CSVプレビューを表示
        setShowCsvPreview(true);
      };
      reader.readAsText(file);
    }
  };

  // バッチごとにメールを送信する関数
  const sendNextBatch = async () => {
    if (remainingEmails.length === 0) {
      setBatchSendingActive(false);
      setMessage(`送信完了: 全${batchProgress.total}件の送信が完了しました`);
      return;
    }
    
    // 現在のバッチを取得
    const currentBatch = remainingEmails.slice(0, batchSize);
    const newRemaining = remainingEmails.slice(batchSize);
    setRemainingEmails(newRemaining);
    
    // 進捗を更新
    const newCurrent = batchProgress.current + 1;
    const nextBatchTime = new Date();
    nextBatchTime.setMinutes(nextBatchTime.getMinutes() + batchInterval);
    
    setBatchProgress({
      current: newCurrent,
      total: batchProgress.total,
      nextBatch: newRemaining.length > 0 ? nextBatchTime.toISOString() : null
    });
    
    // このバッチを送信
    try {
      setMessage(`バッチ ${newCurrent}/${batchProgress.total} を送信中... (${currentBatch.length}件)`);
      
      var baseUrl = window.location.origin;
      const response = await fetch(baseUrl+'/AccountMGT/server/send-mail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipients: currentBatch,
          subject,
          content: addUnsubscribeFooter(content),
          genre,
          creater_code: createrCode,
          from_email: fromEmail,
          from_name: fromName,
          reply_to: replyTo,
          batch_info: {
            current: newCurrent,
            total: batchProgress.total
          }
        })
      });

      const data = await response.json();
      if (data.success) {
        if (newRemaining.length > 0) {
          setMessage(`バッチ ${newCurrent}/${batchProgress.total} 送信完了: ${data.sent}件送信、${data.skipped}件スキップ。次のバッチは ${batchInterval} 分後に送信します。`);
        } else {
          setMessage(`送信完了: 全${batchProgress.total}バッチの送信が完了しました。合計: ${batchProgress.total * batchSize}件`);
          setBatchSendingActive(false);
        }
      } else {
        setMessage(`バッチ ${newCurrent} の送信に失敗しました: ${data.error}。再試行します。`);
        // 失敗した場合は再度キューに戻す
        setRemainingEmails([...currentBatch, ...newRemaining]);
      }
    } catch (error) {
      console.error('バッチ送信エラー:', error);
      setMessage(`バッチ ${newCurrent} の送信中にエラーが発生しました。再試行します。`);
      // 失敗した場合は再度キューに戻す
      setRemainingEmails([...currentBatch, ...newRemaining]);
    }
  };

  // メール送信処理のメインハンドラ
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (csvData.length === 0) {
      setMessage('メールリストが読み込まれていません');
      return;
    }

    if (!subject || !content) {
      setMessage('件名と本文を入力してください');
      return;
    }

    if (!fromEmail || !fromName) {
      setMessage('送信元メールアドレスと送信者名を入力してください');
      return;
    }

    // バッチ送信を使用する場合
    if (useBatchSending) {
      setIsLoading(true);
      
      const totalBatches = Math.ceil(csvData.length / batchSize);
      
      // バッチ送信の状態を初期化
      setBatchProgress({
        current: 0,
        total: totalBatches,
        nextBatch: new Date().toISOString() // すぐに最初のバッチを送信
      });
      
      setRemainingEmails(csvData.map(item => item.email));
      setBatchSendingActive(true);
      
      setMessage(`バッチ送信を開始します。${totalBatches}バッチに分けて送信します。`);
      setIsLoading(false);
      
      return;
    }
    
    // 通常の一括送信（バッチ送信を使用しない場合）
    setIsLoading(true);
    
    try {
      var baseUrl = window.location.origin;
      const response = await fetch(baseUrl+'/AccountMGT/server/send-mail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipients: csvData.map(item => item.email),
          subject,
          content: addUnsubscribeFooter(content),
          genre,
          creater_code: createrCode,
          from_email: fromEmail,
          from_name: fromName,
          reply_to: replyTo
        })
      });

      const data = await response.json();
      if (data.success) {
        setMessage(`送信完了: ${data.sent}件送信、${data.skipped}件スキップ`);
      } else {
        setMessage('送信に失敗しました: ' + data.error);
      }
    } catch (error) {
      console.error('送信エラー:', error);
      setMessage('送信エラーが発生しました');
    } finally {
      setIsLoading(false);
    }
  };

  // バッチ送信を中止
  const handleStopBatchSending = () => {
    setBatchSendingActive(false);
    setRemainingEmails([]);
    setMessage('バッチ送信を中止しました');
  };

  // 配信解除フッターを追加
  const addUnsubscribeFooter = (emailContent) => {
    var baseUrl = 'https://mashimashikun.com';
    
    // 送信元メールアドレスが「noreply@」で始まる場合は、注意書きを強調
    const isNoReplyEmail = fromEmail.toLowerCase().startsWith('noreply@');
    const noReplyWarning = isNoReplyEmail 
      ? '【重要】このメールは送信専用アドレスから送信されています。このメールに返信いただいても対応できませんのでご注意ください。' 
      : '※このメールは送信専用です。こちらのメールに返信しても対応できません。';
    
    // HTMLタグを使用してフッターを追加
    return `${emailContent}
<p>-------------------------------------</p>
<p>${noReplyWarning}</p>
<p>配信を解除するには<a href="https://mashimashikun.com/unsubscribe?email={EMAIL}&genre=${encodeURIComponent(genre)}&creater_code=${createrCode}">こちらをクリック</a>してください。</p>`;
  };

  // プレビュー表示切り替え
  const handlePreviewToggle = () => {
    setPreview(!preview);
  };

  // CSVプレビュー表示切り替え
  const handleCsvPreviewToggle = () => {
    setShowCsvPreview(!showCsvPreview);
  };

  // CSVプレビューの表示件数を増やす
  const handleShowMore = () => {
    setCsvPreviewLimit(prev => prev + 10);
  };

  return (
    <div className="email-form-container">
      <h1>メール配信フォーム</h1>
      
      {message && (
        <div className="message">
          {message}
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            メールリスト (CSV)
          </label>
          <div className="csv-help">
            <p>カンマ区切り（CSV）形式のファイルをアップロードしてください。</p>
            <p>例: email1@example.com, email2@example.com, email3@example.com</p>
          </div>
          <input 
            type="file" 
            accept=".csv" 
            onChange={handleFileUpload}
            disabled={batchSendingActive}
          />
          {csvData.length > 0 && (
            <div className="csv-info">
              <p>
                {csvData.length}件のメールアドレスが読み込まれました
              </p>
              <div>
                <button 
                  type="button" 
                  onClick={handleCsvPreviewToggle}
                  className="secondary-button small-button"
                  disabled={batchSendingActive}
                >
                  {showCsvPreview ? 'リストを隠す' : 'リストを表示'}
                </button>
                <button 
                  type="button" 
                  onClick={() => {
                    setCsvData([]);
                    setShowCsvPreview(false);
                  }}
                  className="secondary-button small-button"
                  disabled={batchSendingActive}
                >
                  クリア
                </button>
              </div>
            </div>
          )}

          {/* CSVプレビュー */}
          {showCsvPreview && csvData.length > 0 && (
            <div className="csv-preview">
              <h3>読み込まれたメールアドレス</h3>
              <ul>
                {csvData.slice(0, csvPreviewLimit).map((item, index) => (
                  <li key={index}>{item.email}</li>
                ))}
              </ul>
              {csvData.length > csvPreviewLimit && (
                <button 
                  type="button" 
                  onClick={handleShowMore}
                  className="secondary-button small-button"
                >
                  さらに表示 ({csvPreviewLimit}/{csvData.length})
                </button>
              )}
            </div>
          )}
        </div>
        
        {csvData.length > batchSize && (
          <div className="form-group batch-settings">
            <label>
              <input
                type="checkbox"
                checked={useBatchSending}
                onChange={(e) => setUseBatchSending(e.target.checked)}
                disabled={batchSendingActive}
              />
              バッチ送信を使用する（サーバー負荷軽減のため推奨）
            </label>
            
            {useBatchSending && (
              <div className="batch-options">
                <div className="batch-option">
                  <label>1バッチあたりの送信数：</label>
                  <select 
                    value={batchSize} 
                    onChange={(e) => setBatchSize(Number(e.target.value))}
                    disabled={batchSendingActive}
                  >
                    <option value="50">50件</option>
                    <option value="100">100件</option>
                    <option value="200">200件</option>
                    <option value="500">500件</option>
                  </select>
                </div>
                
                <div className="batch-option">
                  <label>バッチ間の間隔：</label>
                  <select 
                    value={batchInterval} 
                    onChange={(e) => setBatchInterval(Number(e.target.value))}
                    disabled={batchSendingActive}
                  >
                    <option value="10">10分</option>
                    <option value="20">20分</option>
                    <option value="30">30分</option>
                    <option value="60">60分</option>
                  </select>
                </div>
                
                <div className="batch-info">
                  <p>合計 {Math.ceil(csvData.length / batchSize)} バッチに分割されます。</p>
                  <p>完了までに約 {Math.ceil(csvData.length / batchSize) * batchInterval} 分かかります。</p>
                </div>
              </div>
            )}
          </div>
        )}
        
        <div className="form-group">
          <label>
            送信元メールアドレス
          </label>
          <input 
            type="email" 
            value={fromEmail} 
            onChange={(e) => setFromEmail(e.target.value)}
            placeholder="送信元メールアドレス" 
            disabled={isConfigLoading || batchSendingActive}
            required
          />
        </div>
        
        <div className="form-group">
          <label>
            送信者名
          </label>
          <input 
            type="text" 
            value={fromName} 
            onChange={(e) => setFromName(e.target.value)}
            placeholder="送信者名" 
            disabled={isConfigLoading || batchSendingActive}
            required
          />
        </div>
        
        <div className="form-group">
          <label>
            返信先メールアドレス
          </label>
          <input 
            type="email" 
            value={replyTo} 
            onChange={(e) => setReplyTo(e.target.value)}
            placeholder="返信先メールアドレス" 
            disabled={isConfigLoading || batchSendingActive}
          />
        </div>
        
        <div className="form-group">
          <label>
            件名
          </label>
          <input 
            type="text" 
            value={subject} 
            onChange={(e) => setSubject(e.target.value)}
            placeholder="メールの件名" 
            disabled={batchSendingActive}
            required
          />
        </div>
        
        <div className="form-group">
          <label>
            本文
          </label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)}
            placeholder="メール本文" 
            rows={8}
            disabled={batchSendingActive}
          />
        </div>

        <div className="form-group">
          <label>
            メールジャンル
          </label>
          <input 
            type="text" 
            value={genre} 
            onChange={(e) => setGenre(e.target.value)}
            placeholder="メールのジャンル（例: newsletter, promotion）" 
            disabled={batchSendingActive}
          />
        </div>
        
        <div className="button-group">
          {batchSendingActive ? (
            <button 
              type="button" 
              onClick={handleStopBatchSending}
              className="secondary-button danger-button"
            >
              バッチ送信を中止
            </button>
          ) : (
            <>
              <button 
                type="button" 
                onClick={handlePreviewToggle}
                className="secondary-button"
                disabled={isLoading}
              >
                {preview ? 'プレビューを閉じる' : 'プレビュー'}
              </button>
              
              <button 
                type="submit" 
                disabled={isLoading || csvData.length === 0 || batchSendingActive}
                className="primary-button"
              >
                {isLoading ? '送信中...' : useBatchSending ? 'バッチ送信を開始' : 'メールを送信'}
              </button>
            </>
          )}
        </div>
      </form>
      
      {preview && (
        <div className="preview-container">
          <h2>プレビュー</h2>
          <div className="email-preview">
            <div className="email-subject">
              件名: {subject || '(件名なし)'}
            </div>
            <div 
              className="email-content"
              dangerouslySetInnerHTML={{ 
                __html: content 
                  ? addUnsubscribeFooter(content).replace(/\n/g, '<br>') 
                  : '(本文なし)'
              }} 
            />
          </div>
        </div>
      )}
      
      {/* バッチ送信用CSS */}
      <style jsx>{`
        .batch-settings {
          background-color: #f8f9fa;
          padding: 15px;
          border-radius: 8px;
          margin-bottom: 20px;
          border: 1px solid #e2e8f0;
        }
        
        .batch-options {
          margin-top: 10px;
          padding-left: 25px;
        }
        
        .batch-option {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
        
        .batch-option label {
          margin-right: 10px;
          margin-bottom: 0;
        }
        
        .batch-option select {
          padding: 5px 10px;
          border-radius: 4px;
          border: 1px solid #ccc;
        }
        
        .batch-info {
          margin-top: 10px;
          font-size: 0.9em;
          color: #666;
        }
        
        .batch-info p {
          margin: 5px 0;
        }
        
        .danger-button {
          background-color: #fff;
          color: #e53e3e;
          border: 1px solid #e53e3e;
        }
        
        .danger-button:hover {
          background-color: #fff5f5;
        }
      `}</style>
    </div>
  );
};

export default MailFormFromHakaseEA;