import React from "react";
import styled from '@emotion/styled';
import "../Style/font.css"; // ✅ 直接CSSを読み込むだけでOK
import ThanksIMG from "../images/thanks.svg"; // 画像パスは実際のものに合わせてください
import Button from '@mui/material/Button';

// ✅ PcDrawer のスタイル
const PcDrawer = styled('div')({
  display: 'block',
});

// ✅ Main のスタイル
const Main = styled('div')({
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  height: '100%',
  textAlign: 'center',
});

// ✅ IllArea のスタイル
const IllArea = styled('div')({
  marginTop: '64px',
});

// ✅ IllImage の修正（react-image を直接 styled しない）
const IllImage = styled('img')({
  marginLeft: '35%',
  height: '30%',
  display: 'block',
  width: '30%',
});

// ✅ メッセージ部分のスタイル
const ThanksMsgBold = styled('div')({
  display: 'block',
  fontSize: '28px',
  fontWeight: '700',
  textAlign: 'center',
  fontFamily: 'Noto Sans Japanese',
  color: '#333333',
});

const ThanksMsgRegular = styled('div')({
  display: 'block',
  fontSize: '20px',
  textAlign: 'center',
  fontFamily: 'Noto Sans Japanese',
  color: '#000000',
  opacity: '0.5',
});

// ✅ ButtonArea のスタイル
const ButtonArea = styled('div')({
  width: '100%',
});

// ✅ TopButton の修正（MUI の Button を適切にラップ）
const TopButton = styled((props) => <Button {...props} />)({
  backgroundColor: '#ffffff',
  color: '#0F56A8',
  width: '200px',
  fontSize: '14px',
  borderColor: '#0f56A8',
  borderStyle: 'none',
  textAlign: 'center',
});

const Thanks = () => {
  return (
    <div className="body"> {/* ✅ className 直接指定 */}
      <PcDrawer>
        <Main>
          <IllArea>
            {/* ✅ IllImage に react-image を直接使わず、img タグを使用 */}
            <IllImage src={ThanksIMG} alt="thanks" />
            <ThanksMsgBold>
              申請ありがとうございます
            </ThanksMsgBold>
            <ThanksMsgRegular>
              入力いただいたメールアドレス宛に
              <br />
              ツールを送付させていただきます。
            </ThanksMsgRegular>
            <ButtonArea>
              <TopButton
                variant="outlined"
                onClick={() => window.location.href = '/'}
              >
                トップへ戻る
              </TopButton>
            </ButtonArea>
          </IllArea>
        </Main>
      </PcDrawer>
    </div>
  );
};

export default Thanks;