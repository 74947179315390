import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Button, Alert, Spinner, Badge, Tabs, Tab, Form, Modal } from 'react-bootstrap';
import { FaPaypal, FaArrowLeft, FaExclamationTriangle, FaInfoCircle, FaExternalLinkAlt, FaCopy, FaLock, FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './SubscriptionManagementDashboard.css';

const SubscriptionManagementDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState([]);
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentEditSubscription, setCurrentEditSubscription] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  
  const navigate = useNavigate();

  // 管理者認証をチェック
  useEffect(() => {
    // ローカルストレージから認証状態を確認
    const authStatus = localStorage.getItem('admin_authenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  // 管理者認証処理
  const handleAdminAuth = (e) => {
    e.preventDefault();
    
    // 実際の環境では、サーバーサイドでの認証処理を実装すべきです
    if (adminPassword === 'admin123') { // 本番環境では実際の認証方法に置き換える
      setIsAuthenticated(true);
      localStorage.setItem('admin_authenticated', 'true');
      setError(null);
      
      // 認証後、サブスクリプション情報を取得
      fetchSubscriptions();
    } else {
      setError('管理者パスワードが正しくありません');
    }
  };
  
  // 管理者ログアウト処理
  const handleLogout = () => {
    localStorage.removeItem('admin_authenticated');
    setIsAuthenticated(false);
    navigate('/dashboard');
  };

  // サブスクリプション情報を取得
  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('サブスクリプション情報の取得を開始します...');
      
      const response = await fetch('/AccountMGT/server/api/subscriptions.php');
      console.log('APIレスポンスのステータス:', response.status);
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('取得したサブスクリプションデータ:', data);
      
      if (data.success) {
        // データをコンソールに出力して確認
        console.log('アクティブなサブスクリプション:', data.active_subscriptions);
        console.log('非アクティブなサブスクリプション:', data.inactive_subscriptions);
        
        setActiveSubscriptions(data.active_subscriptions || []);
        setInactiveSubscriptions(data.inactive_subscriptions || []);
      } else {
        setError(data.message || 'サブスクリプション情報の取得に失敗しました');
        console.error('サブスクリプション取得エラー (APIエラー):', data.message);
      }
    } catch (err) {
      console.error('サブスクリプション取得エラー:', err);
      setError(err.message || 'サブスクリプション情報の取得中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };

  // 特定のサブスクリプションの詳細情報を取得
  const fetchSubscriptionDetail = async (subscriptionId) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`/AccountMGT/server/api/subscriptions.php?id=${subscriptionId}`);
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setSubscriptionDetail(data.subscription);
        setShowDetail(true);
      } else {
        setError(data.message || 'サブスクリプション詳細情報の取得に失敗しました');
      }
    } catch (err) {
      console.error('サブスクリプション詳細取得エラー:', err);
      setError(err.message || 'サブスクリプション詳細情報の取得中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };

  // ページ読み込み時にサブスクリプション情報を取得（認証済みの場合のみ）
  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscriptions();
    }
  }, [isAuthenticated, refreshData]);

  // サブスクリプションステータスに応じたバッジの色を返す
  const getStatusBadgeVariant = (status) => {
    switch (status?.toUpperCase()) {
      case 'ACTIVE':
        return 'success';
      case 'APPROVAL_PENDING':
        return 'warning';
      case 'SUSPENDED':
        return 'secondary';
      case 'CANCELLED':
        return 'danger';
      default:
        return 'info';
    }
  };

  // 日時を整形して表示
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleString('ja-JP');
    } catch (e) {
      return dateString;
    }
  };

  // 詳細画面から一覧に戻る
  const handleBackToList = () => {
    setShowDetail(false);
    setSubscriptionDetail(null);
  };

  // サブスクリプション編集モーダルを開く
  const handleOpenEditModal = (subscription) => {
    setCurrentEditSubscription({...subscription});
    setShowEditModal(true);
  };

  // サブスクリプション編集処理
  const handleSaveSubscription = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('/AccountMGT/server/api/subscriptions.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentEditSubscription),
      });
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setShowEditModal(false);
        setRefreshData(prev => !prev); // データを再取得するためのトリガー
      } else {
        setError(data.message || 'サブスクリプション情報の更新に失敗しました');
      }
    } catch (err) {
      console.error('サブスクリプション更新エラー:', err);
      setError(err.message || 'サブスクリプション情報の更新中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };

  // サブスクリプション削除処理
  const handleDeleteSubscription = async (subscriptionId) => {
    if (!window.confirm('このサブスクリプションを削除してもよろしいですか？この操作は元に戻せません。')) {
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`/AccountMGT/server/api/subscriptions.php?id=${subscriptionId}`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // 詳細表示中の場合はリストに戻る
        if (showDetail && subscriptionDetail?.id === subscriptionId) {
          setShowDetail(false);
          setSubscriptionDetail(null);
        }
        
        setRefreshData(prev => !prev); // データを再取得するためのトリガー
      } else {
        setError(data.message || 'サブスクリプション情報の削除に失敗しました');
      }
    } catch (err) {
      console.error('サブスクリプション削除エラー:', err);
      setError(err.message || 'サブスクリプション情報の削除中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };
  
  // 管理者認証画面を表示
  if (!isAuthenticated) {
    return (
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Header as="h4" className="d-flex align-items-center bg-primary text-white">
                <FaLock className="me-2" /> 管理者専用ページ
              </Card.Header>
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                
                <Alert variant="info">
                  <FaInfoCircle className="me-2" />
                  このページは管理者専用です。サブスクリプション情報を表示します。
                </Alert>
                
                <Form onSubmit={handleAdminAuth}>
                  <Form.Group className="mb-3">
                    <Form.Label>管理者パスワード</Form.Label>
                    <Form.Control
                      type="password"
                      value={adminPassword}
                      onChange={(e) => setAdminPassword(e.target.value)}
                      placeholder="管理者パスワードを入力"
                      required
                    />
                  </Form.Group>
                  
                  <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                      認証
                    </Button>
                    <Button variant="outline-secondary" onClick={() => navigate('/dashboard')}>
                      ダッシュボードに戻る
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  // 詳細表示画面
  if (showDetail && subscriptionDetail) {
    return (
      <Container className="py-5">
        <Card className="shadow-sm mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-white">
            <div className="d-flex align-items-center">
              <FaPaypal className="me-2" />
              <h4 className="mb-0">サブスクリプション詳細 (管理者専用)</h4>
            </div>
            <div>
              <Button 
                variant="warning" 
                size="sm" 
                className="me-2"
                onClick={() => handleOpenEditModal(subscriptionDetail)}
              >
                <FaEdit className="me-1" /> 編集
              </Button>
              <Button 
                variant="outline-light" 
                size="sm" 
                onClick={handleBackToList}
              >
                <FaArrowLeft className="me-1" /> 一覧に戻る
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <Row className="mb-4">
              <Col md={6}>
                <h5>基本情報</h5>
                <Table bordered hover responsive>
                  <tbody>
                    <tr>
                      <th>サブスクリプションID</th>
                      <td>
                        <code>{subscriptionDetail.subscription_id}</code>
                        <Button 
                          variant="link" 
                          size="sm" 
                          className="p-0 ms-2"
                          onClick={() => navigator.clipboard.writeText(subscriptionDetail.subscription_id)}
                        >
                          <FaCopy />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th>ステータス</th>
                      <td>
                        <Badge bg={getStatusBadgeVariant(subscriptionDetail.status)}>
                          {subscriptionDetail.status}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th>作成日時</th>
                      <td>{formatDate(subscriptionDetail.created_at)}</td>
                    </tr>
                    <tr>
                      <th>最終更新</th>
                      <td>{formatDate(subscriptionDetail.updated_at)}</td>
                    </tr>
                    <tr>
                      <th>プランID</th>
                      <td><code>{subscriptionDetail.plan_id}</code></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={6}>
                <h5>顧客情報</h5>
                <Table bordered hover responsive>
                  <tbody>
                    <tr>
                      <th>名前</th>
                      <td>{subscriptionDetail.subscriber_name || '-'}</td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>{subscriptionDetail.subscriber_email || '-'}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            
            <h5>支払い情報</h5>
            <Table bordered hover responsive>
              <tbody>
                <tr>
                  <th>次回請求日</th>
                  <td>{formatDate(subscriptionDetail.next_billing_time)}</td>
                </tr>
                <tr>
                  <th>前回請求日</th>
                  <td>{formatDate(subscriptionDetail.last_payment_time)}</td>
                </tr>
                <tr>
                  <th>支払い方法</th>
                  <td>{subscriptionDetail.payment_method || 'PayPal'}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between">
            <Button 
              variant="danger" 
              size="sm" 
              onClick={() => handleDeleteSubscription(subscriptionDetail.subscription_id)}
            >
              <FaTrash className="me-1" /> 削除
            </Button>
            <Button variant="outline-secondary" size="sm" onClick={() => navigate('/dashboard')}>
              ダッシュボードに戻る
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      <Card className="shadow-sm mb-4">
        <Card.Header className="bg-dark text-white d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <FaPaypal className="me-2" />
            <h4 className="mb-0">サブスクリプション管理 (管理者専用)</h4>
          </div>
          <Button 
            variant="outline-light" 
            size="sm" 
            onClick={() => navigate('/dashboard')}
          >
            <FaArrowLeft className="me-1" /> ダッシュボード
          </Button>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              <FaExclamationTriangle className="me-2" />
              {error}
            </Alert>
          )}
          
          <Tabs defaultActiveKey="active" className="mb-3">
            <Tab eventKey="active" title="アクティブなサブスクリプション">
              <div className="table-responsive">
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner animation="border" variant="primary" />
                    <p className="mt-3">サブスクリプション情報を読み込んでいます...</p>
                  </div>
                ) : activeSubscriptions.length > 0 ? (
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>サブスクリプションID</th>
                        <th>ステータス</th>
                        <th>作成日</th>
                        <th>最終更新</th>
                        <th>顧客情報</th>
                        <th>アクション</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeSubscriptions.map(subscription => (
                        <tr key={subscription.subscription_id}>
                          <td><code>{subscription.subscription_id}</code></td>
                          <td>
                            <Badge bg={getStatusBadgeVariant(subscription.status)}>
                              {subscription.status}
                            </Badge>
                          </td>
                          <td>{formatDate(subscription.created_at)}</td>
                          <td>{formatDate(subscription.updated_at)}</td>
                          <td>
                            {subscription.subscriber_email ? (
                              <span>{subscription.subscriber_email}</span>
                            ) : (
                              <span className="text-muted">情報なし</span>
                            )}
                          </td>
                          <td>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="me-2"
                              onClick={() => fetchSubscriptionDetail(subscription.subscription_id)}
                            >
                              詳細
                            </Button>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleDeleteSubscription(subscription.subscription_id)}
                            >
                              削除
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="warning">
                    アクティブなサブスクリプションはありません。
                  </Alert>
                )}
              </div>
            </Tab>
            <Tab eventKey="inactive" title="非アクティブなサブスクリプション">
              <div className="table-responsive">
                {loading ? (
                  <div className="text-center py-5">
                    <Spinner animation="border" variant="primary" />
                    <p className="mt-3">サブスクリプション情報を読み込んでいます...</p>
                  </div>
                ) : inactiveSubscriptions.length > 0 ? (
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>サブスクリプションID</th>
                        <th>ステータス</th>
                        <th>作成日</th>
                        <th>最終更新</th>
                        <th>顧客情報</th>
                        <th>アクション</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inactiveSubscriptions.map(subscription => (
                        <tr key={subscription.subscription_id}>
                          <td><code>{subscription.subscription_id}</code></td>
                          <td>
                            <Badge bg={getStatusBadgeVariant(subscription.status)}>
                              {subscription.status}
                            </Badge>
                          </td>
                          <td>{formatDate(subscription.created_at)}</td>
                          <td>{formatDate(subscription.updated_at)}</td>
                          <td>
                            {subscription.subscriber_email ? (
                              <span>{subscription.subscriber_email}</span>
                            ) : (
                              <span className="text-muted">情報なし</span>
                            )}
                          </td>
                          <td>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="me-2"
                              onClick={() => fetchSubscriptionDetail(subscription.subscription_id)}
                            >
                              詳細
                            </Button>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleDeleteSubscription(subscription.subscription_id)}
                            >
                              削除
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="warning">
                    非アクティブなサブスクリプションはありません。
                  </Alert>
                )}
              </div>
            </Tab>
          </Tabs>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={() => navigate('/dashboard')}>
            ダッシュボードに戻る
          </Button>
          <Button variant="outline-danger" size="sm" onClick={handleLogout}>
            管理者ログアウト
          </Button>
        </Card.Footer>
      </Card>

      {/* サブスクリプション編集モーダル */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>サブスクリプション編集</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentEditSubscription && (
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>ステータス</Form.Label>
                    <Form.Select 
                      value={currentEditSubscription.status || ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, status: e.target.value})}
                    >
                      <option value="ACTIVE">アクティブ (ACTIVE)</option>
                      <option value="SUSPENDED">一時停止 (SUSPENDED)</option>
                      <option value="CANCELLED">キャンセル済み (CANCELLED)</option>
                      <option value="APPROVAL_PENDING">承認待ち (APPROVAL_PENDING)</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>プランID</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={currentEditSubscription.plan_id || ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, plan_id: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>顧客名</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={currentEditSubscription.subscriber_name || ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, subscriber_name: e.target.value})}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>顧客メールアドレス</Form.Label>
                    <Form.Control 
                      type="email" 
                      value={currentEditSubscription.subscriber_email || ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, subscriber_email: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>次回請求日</Form.Label>
                    <Form.Control 
                      type="datetime-local" 
                      value={currentEditSubscription.next_billing_time ? new Date(currentEditSubscription.next_billing_time).toISOString().slice(0, 16) : ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, next_billing_time: e.target.value})}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>前回支払日</Form.Label>
                    <Form.Control 
                      type="datetime-local" 
                      value={currentEditSubscription.last_payment_time ? new Date(currentEditSubscription.last_payment_time).toISOString().slice(0, 16) : ''}
                      onChange={(e) => setCurrentEditSubscription({...currentEditSubscription, last_payment_time: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={handleSaveSubscription} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : '保存'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SubscriptionManagementDashboard; 