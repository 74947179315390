import React from "react";
import styled from '@emotion/styled';
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import Font from "../Style/font.css";
import ThanksIMG from "../images/thanks.svg";
import Button from '@mui/material/Button';

const StyledButton = styled(Button)({
  '&.detail-button': {
    backgroundColor: '#0F56A8',
    color: '#ffffff',
    width: '200px',
    fontSize: '14px',
    borderColor: '#0f56A8',
    borderStyle: 'none',
    fontFamily: 'Noto Sans Japanese',
    textAlign: 'center',
  },
});

const ThanksLeoFX = () => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    const id = window.location.search.substring(1).split('=');
    if (typeof id[1] === "undefined" || id[1] === "") {
      navigate('/leofxtool?id=LEOFX');
    } else {
      navigate(`/leofxtool?id=${id[1]}=${id[2]}`);
    }
  };

  return (
    <div className={Font.body}>
      <div style={{ display: 'block', textAlign: 'center' }}>
        <div style={{ marginBottom: '64px' }} />
        <div>
          <div style={{ marginBottom: '64px' }} />
          <img src={ThanksIMG} alt="Thanks" style={{ height: '302px', width: '100%', display: 'block' }} />
          <div style={{ marginBottom: '64px' }} />
          <div style={{ fontSize: '28px', fontWeight: '700', color: '#333333', textAlign: 'center' }}>
            申請ありがとうございます。
          </div>
          <div style={{ marginBottom: '16px' }} />
          <div style={{ fontSize: '20px', color: '#d9394e', fontWeight: 'bold', textAlign: 'center', opacity: '0.5' }}>
            メールを送信致しました。
          </div>
          <div style={{ fontSize: '20px', color: '#d9394e', fontWeight: 'bold', textAlign: 'center', opacity: '0.5' }}>
            迷惑メールに入っている可能性もございます。
          </div>
          <div style={{ fontSize: '20px', color: '#d9394e', fontWeight: 'bold', textAlign: 'center', opacity: '0.5' }}>
            ご確認の程よろしくお願い致します。
          </div>
          <div style={{ marginBottom: '64px' }} />
          <div>
            <StyledButton
              variant="contained"
              className="detail-button"
              onClick={handleOnclick}
            >
              申請画面に戻る
            </StyledButton>
          </div>
          <div style={{ marginBottom: '32px' }} />
        </div>
      </div>
    </div>
  );
};

export default ThanksLeoFX;