import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import SorryIMG from "../images/404error.png";

// ✅ Styled Components
const PcDrawer = styled.div`
  display: block;
`;

const Main = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px 20px;
`;

const IllImageWrapper = styled.div`
  height: 302px;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const BoldMessage = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #333333;
  margin-top: 20px;
`;

const RegularMessage = styled.div`
  font-size: 20px;
  color: #000000;
  opacity: 0.7;
  margin-top: 10px;
`;

const ButtonArea = styled.div`
  margin-top: 24px;
`;

const StyledButton = styled(Button)`
  background-color: #0F56A8;
  color: #ffffff;
  width: 200px;

  &:hover {
    background-color: #0d3e80;
  }
`;

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <PcDrawer>
      <Main>
        <IllImageWrapper>
          <img src={SorryIMG} alt="404 Error" style={{ width: '100%', height: '100%' }} />
        </IllImageWrapper>

        <BoldMessage>お探しのページが見つかりません。</BoldMessage>
        <RegularMessage>ページが存在しないか、移動された可能性があります。</RegularMessage>
        <RegularMessage>ホームに戻るか、再度お試しください。</RegularMessage>

        <ButtonArea>
          <StyledButton variant="contained" onClick={() => navigate('/')}>
            ホームへ戻る
          </StyledButton>
        </ButtonArea>
      </Main>
    </PcDrawer>
  );
};

export default ErrorPage;