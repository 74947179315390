import React from "react";
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ThanksIMG from "../images/thanks.svg";
import Button from '@mui/material/Button';

// ✅ StyledButtonの修正
const StyledButton = styled(Button)({
  backgroundColor: '#0F56A8',
  color: '#ffffff',
  width: '200px',
  fontSize: '14px',
  borderColor: '#0f56A8',
  borderStyle: 'none',
  '&:hover': {
    backgroundColor: '#0d3e80',
  },
});

const ThanksEA = () => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id') || '';
    const extra = params.get('extra') || '';

    if (!id) {
      navigate('/ea_form');
    } else {
      navigate(`/ea_form?id=${id}&extra=${extra}`);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <img src={ThanksIMG} alt="Thanks" style={{ height: '300px', margin: '20px auto' }} />
      <h2>申請ありがとうございます。</h2>
      <p>メールを送信致しました。</p>
      <p>迷惑メールに入っている可能性もございます。</p>
      <p>ご確認の程よろしくお願い致します。</p>
      <StyledButton variant="contained" onClick={handleOnclick}>
        申請画面に戻る
      </StyledButton>
    </div>
  );
};

export default ThanksEA;