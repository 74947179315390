import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

// Firebaseの設定
const firebaseConfig = {
    apiKey: "AIzaSyAP4-lGH0V40vC4Zn-5-abgzypDm3C4Yas",
    authDomain: "devea-77052.firebaseapp.com",
    projectId: "devea-77052",
    storageBucket: "devea-77052.firebasestorage.app",
    messagingSenderId: "775122064108",
    appId: "1:775122064108:web:34447268233e1e83ed4bc9",
    measurementId: "G-D3DN8RLLS8"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app); // Analyticsの初期化

// トークンを取得する関数
export const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        console.log('通知の許可が得られました。');
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js'); // Service Workerを登録
        const token = await getToken(messaging, { vapidKey: 'BN2g_Z7m9ASg6kxmmhW2-Aa_xaonmpUxDb7LI_OhXjUjNFgki9erxqpb0Pu4v8iFwR-l4im-Hw2L6A296N7uQnA' }); // ここに生成した公開鍵を設定
        console.log('FCM Token:', token);
        // ここでトークンをサーバーに送信する処理を追加
    } else {
        console.log('通知の許可が得られませんでした。');
    }
};

export const subscribeUser = async () => {
    const registration = await navigator.serviceWorker.ready;
    const existingSubscription = await registration.pushManager.getSubscription();

    // 既存のサブスクリプションがある場合は解除
    if (existingSubscription) {
        await existingSubscription.unsubscribe();
        console.log('既存のサブスクリプションを解除しました。');
    }

    const applicationServerKey = urlB64ToUint8Array('BN2g_Z7m9ASg6kxmmhW2-Aa_xaonmpUxDb7LI_OhXjUjNFgki9erxqpb0Pu4v8iFwR-l4im-Hw2L6A296N7uQnA'); // ここに生成した公開鍵を設定
    const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
    });

    console.log('User is subscribed:', subscription);
    console.log('Keys:', subscription.getKey('p256dh'), subscription.getKey('auth'));

    const p256dhKey = subscription.getKey('p256dh'); // p256dhキーを取得
    const authKey = subscription.getKey('auth'); // authキーを取得

    console.log('p256dh:', btoa(String.fromCharCode.apply(null, new Uint8Array(p256dhKey))));
    console.log('auth:', btoa(String.fromCharCode.apply(null, new Uint8Array(authKey))));

    return subscription; // ここでsubscriptionオブジェクトを返す
};

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}