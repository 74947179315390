import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { FaPaypal, FaLock, FaCheckCircle, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import './PayPalSecretInput.css';
import axios from 'axios';

const PayPalSecretInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [userId, setUserId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ type: null, text: '' });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [isOAuthCallback, setIsOAuthCallback] = useState(false);
  
  // 管理者認証をチェック
  useEffect(() => {
    // ローカルストレージから認証状態を確認
    const authStatus = localStorage.getItem('admin_authenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);
  
  // URLからクエリパラメータを解析
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('auth_code');
    const id = searchParams.get('user_id');
    
    if (code) {
      setAuthCode(code);
      setIsOAuthCallback(true);
    }
    
    if (id) {
      setUserId(id);
    }
  }, [location]);
  
  // 管理者認証処理
  const handleAdminAuth = (e) => {
    e.preventDefault();
    
    // 実際の環境では、サーバーサイドでの認証処理を実装すべきです
    if (adminPassword === 'admin123') { // 本番環境では実際の認証方法に置き換える
      setIsAuthenticated(true);
      localStorage.setItem('admin_authenticated', 'true');
      setError('');
    } else {
      setError('管理者パスワードが正しくありません');
    }
  };
  
  // 管理者ログアウト処理
  const handleLogout = () => {
    localStorage.removeItem('admin_authenticated');
    setIsAuthenticated(false);
    navigate('/dashboard');
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!clientSecret.trim()) {
      setError('クライアントシークレットを入力してください');
      return;
    }
    
    if (!authCode) {
      setError('認証コードが見つかりません。PayPal認証から再度やり直してください');
      return;
    }
    
    setLoading(true);
    setError('');
    setSuccess('');
    
    try {
      console.log('送信データ:', {
        client_secret: clientSecret,
        auth_code: authCode,
        user_id: userId
      });
      
      const response = await axios.post('/AccountMGT/server/complete-paypal-oauth.php', {
        client_secret: clientSecret,
        auth_code: authCode,
        user_id: userId
      });
      
      console.log('API応答の全体:', response);
      console.log('認証レスポンス:', response.data);
      
      if (response.data.success || response.data.status === 'success') {
        setMessage({
          type: 'success',
          text: response.data.message || 'PayPal認証が完了しました。'
        });
        
        // 成功時のリダイレクト
        const redirectTo = response.data.redirect_to || '/paypal-subscriptions';
        console.log('リダイレクト先:', redirectTo);
        
        setTimeout(() => {
          navigate(redirectTo);
        }, 1500);
      } else {
        setMessage({
          type: 'danger',
          text: response.data.message || response.data.error || 'エラーが発生しました。'
        });
      }
    } catch (error) {
      console.error('認証エラー詳細:', error);
      
      let errorMessage = 'PayPal認証中にエラーが発生しました。';
      if (error.response) {
        console.error('エラーレスポンス:', error.response);
        if (error.response.data) {
          console.error('エラーデータ:', error.response.data);
          errorMessage = error.response.data.message || error.response.data.error || errorMessage;
        }
      }
      
      // 認証コードが無効または期限切れの場合の特別なメッセージ
      const isAuthCodeExpired = errorMessage.includes('認証コードが無効') || 
                                errorMessage.includes('期限切れ') || 
                                errorMessage.includes('Authorization code');
      
      setMessage({
        type: 'danger',
        text: errorMessage,
        isAuthCodeExpired: isAuthCodeExpired
      });
      
      // 認証コードエラーの場合は、再認証を促す
      if (isAuthCodeExpired) {
        setMessage(prev => ({
          ...prev,
          showRetryButton: true
        }));
      } else {
        // その他のエラー時には一定時間後にエラーページへリダイレクト
        setTimeout(() => {
          navigate(`/paypal-error?error=${encodeURIComponent(errorMessage)}`);
        }, 3000);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // OAuth認証後のコールバックでアクセスされた場合（一般ユーザー）
  if (isOAuthCallback && !isAuthenticated) {
    return (
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card className="shadow-sm">
              <Card.Header className="bg-primary text-white d-flex align-items-center">
                <FaPaypal className="me-2" />
                <h4 className="mb-0">PayPal認証完了</h4>
              </Card.Header>
              <Card.Body className="p-4 text-center">
                <div className="mb-4">
                  <FaCheckCircle size={50} className="text-success mb-3" />
                  <h5>PayPal認証が完了しました</h5>
                  <p className="text-muted">
                    認証情報が正常に受け取られました。<br />
                    これで購入手続きは完了です。
                  </p>
                </div>

                <Alert variant="success">
                  <p className="mb-0">ありがとうございます！サブスクリプションが正常に設定されました。</p>
                </Alert>

                <div className="mt-4">
                  <p className="text-muted small">
                    ご利用ありがとうございます。サブスクリプション管理に関する詳細は、
                    PayPalアカウントでご確認いただけます。
                  </p>
                  <div className="d-grid gap-2">
                    <Button 
                      variant="primary" 
                      onClick={() => navigate('/dashboard')}
                    >
                      ダッシュボードに戻る
                    </Button>
                    <Button 
                      variant="outline-secondary" 
                      href="https://www.paypal.com/myaccount/autopay/" 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PayPalアカウントでサブスクリプションを管理
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  
  // 管理者認証画面を表示
  if (!isAuthenticated) {
    return (
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Header as="h4" className="d-flex align-items-center bg-warning text-white">
                <FaLock className="me-2" /> 管理者専用ページ
              </Card.Header>
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                
                <Alert variant="info">
                  <FaInfoCircle className="me-2" />
                  このページは管理者専用です。PayPal連携の最終設定を行います。
                </Alert>
                
                <Form onSubmit={handleAdminAuth}>
                  <Form.Group className="mb-3">
                    <Form.Label>管理者パスワード</Form.Label>
                    <Form.Control
                      type="password"
                      value={adminPassword}
                      onChange={(e) => setAdminPassword(e.target.value)}
                      placeholder="管理者パスワードを入力"
                      required
                    />
                  </Form.Group>
                  
                  <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                      認証
                    </Button>
                    <Button variant="outline-secondary" onClick={() => navigate('/dashboard')}>
                      ダッシュボードに戻る
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={10} lg={8}>
          <Card className="shadow-sm">
            <Card.Header className="d-flex align-items-center bg-dark text-white">
              <FaPaypal className="me-2" />
              <h4 className="mb-0">PayPal Developer API 連携 (管理者専用)</h4>
            </Card.Header>
            <Card.Body className="p-4">
              {error && <Alert variant="danger">{error}</Alert>}
              {message.type && <Alert variant={message.type}>{message.text}</Alert>}
              {success && (
                <Alert variant="success">
                  <FaCheckCircle className="me-2" />
                  {success}
                  <div className="mt-2">
                    <Spinner size="sm" animation="border" className="me-2" />
                    サブスクリプション管理ページに移動しています...
                  </div>
                </Alert>
              )}
              
              <div className="text-center mb-4">
                <h5>PayPal連携 - 最終ステップ</h5>
                <p className="text-muted">
                  PayPal Developerの認証に成功しました！
                  <br />
                  クライアントシークレットを入力して設定を完了してください。
                </p>
              </div>
              
              {message.type === 'danger' && message.showRetryButton && (
                <Alert variant="warning">
                  <FaExclamationTriangle className="me-2" />
                  {message.text}
                  <div className="mt-3">
                    <Button 
                      variant="primary" 
                      onClick={() => navigate('/paypal-dev-auth')}
                      size="sm"
                    >
                      PayPal認証をやり直す
                    </Button>
                  </div>
                </Alert>
              )}
              
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-4">
                  <Form.Label>
                    <FaLock className="me-2" />
                    PayPal クライアントシークレット
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="例: EFG12345abcde67890..."
                    value={clientSecret}
                    onChange={(e) => setClientSecret(e.target.value)}
                    disabled={loading || success}
                    required
                  />
                  <Form.Text className="text-muted">
                    PayPal Developerダッシュボードにあるアプリのクライアントシークレットを入力してください。
                    <br />
                    この情報はサーバー上に安全に保存され、PayPal APIとの通信にのみ使用されます。
                  </Form.Text>
                </Form.Group>
                
                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loading || success}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" className="me-2" />
                        処理中...
                      </>
                    ) : (
                      'PayPal連携を完了する'
                    )}
                  </Button>
                </div>
              </Form>
              
              <div className="mt-4 text-center">
                <p className="text-muted mb-1">
                  完了後、WebhookベースのPayPalサブスクリプション管理ダッシュボードが利用可能になります。
                </p>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="link"
                    onClick={() => navigate('/paypal-dev-auth')}
                    disabled={loading || success}
                  >
                    最初からやり直す
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => navigate('/dashboard')}
                    disabled={loading || success}
                  >
                    ダッシュボードに戻る
                  </Button>
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
              <Button variant="outline-secondary" size="sm" onClick={() => navigate('/dashboard')}>
                ダッシュボードに戻る
              </Button>
              <Button variant="outline-danger" size="sm" onClick={handleLogout}>
                管理者ログアウト
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayPalSecretInput; 