import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { requestNotificationPermission, subscribeUser } from './pushNotification';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import MonthlySalesSegmentAnalysis from './containers/SALESAnalysis';
import './index.css';

//遷移先
import RequestForm from './containers/RequestForm';
import RequestFormEA from './containers/RequestFormEA';
import RequestFormEANT from './containers/RequestFormEANT';
import RequestFormCal from './containers/RequestFormCalculator';
import RequestFormCalTrial from './containers/RequestFormCalculatorTrial';
import RequestFormLeofx from './containers/RequestFormLeofx';
import Thanks from './containers/Thanks';
import ThanksEA from './containers/ThanksEA';
import ThanksEANT from './containers/ThanksEANT';
import ThanksEAHACHI from './containers/ThanksEAHACHI';
import ThanksLotTool from './containers/ThanksLotTool';
import ThanksLotToolTrial from './containers/ThanksLotToolTrial';
import SorryLotToolTrial from './containers/SorryLotToolTrial';
import Sorry from './containers/Sorry';
import ThanksPaidHachiForm from './containers/ThanksPaidHachiForm';
import ThanksLeoFX from './containers/ThanksLeoFX';
import ErrorPage from './containers/ErrorPage';
import DeveloperPage from './containers/DeveloperPage';
import RequestFormEAHACHI from './containers/RequestFormEAHACHI';
import RequestFormPaidHachi from './containers/RequestFormPaidHachi';
import AdminDashboard from './containers/AdminDashboard';
import MailFormFromHakaseEA from './containers/MailFormFromHakaseEA';
import MailUnsubscribe from './containers/MailUnsubscribe';
import HachiLotCal from './containers/HACHILotCal';
import PaymentManagementLEO from './containers/PaymentManagementLEO';
import PayPalOAuth from './containers/PayPalOAuth';
import PayPalSuccess from './containers/PayPalSuccess';
import PayPalError from './containers/PayPalError';
import PayPalDevAuth from './containers/PayPalDevAuth';
import PayPalSecretInput from './containers/PayPalSecretInput';
import PayPalSubscriptionDashboard from './containers/PayPalSubscriptionDashboard';
// 新しいサブスクリプション管理コンポーネント
import SubscriptionManagementDashboard from './containers/SubscriptionManagementDashboard';

// テーマの作成
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#0F56A8',
    },
    secondary: {
      main: '#FF8C00',
    },
  },
});

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAP4-lGH0V40vC4Zn-5-abgzypDm3C4Yas",
  authDomain: "devea-77052.firebaseapp.com",
  projectId: "devea-77052",
  storageBucket: "devea-77052.firebasestorage.app",
  messagingSenderId: "775122064108",
  appId: "1:775122064108:web:34447268233e1e83ed4bc9",
  measurementId: "G-D3DN8RLLS8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Firebase Messagingの初期化
const messaging = getMessaging(app);

const App = () => {
  useEffect(() => {
    const init = async () => {
      await requestNotificationPermission();
      const subscription = await subscribeUser();
      
      // サーバーにサブスクリプション情報を送信する処理を追加
      //http://localhost:5000/api/subscribe
      fetch('http://mashimashikun.com/AccountMGT/server/server.php?action=subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.ok) {
          console.log('サブスクリプション情報がサーバーに送信されました。');
        } else {
          console.error('サブスクリプション情報の送信に失敗しました。');
        }
      })
      .catch(error => {
        console.error('サーバーへの送信中にエラーが発生しました:', error);
      });
      // プッシュ通知を送信する処理を追加
      //fetch('https://291a-2400-4050-8c81-cf00-9429-b030-3121-351f.ngrok-free.app/api/sendNotification', {

      // 本番環境では、ngrokのURLは使用しません。
      // 代わりに、さくらレンタルサーバーのURLを使用します。
      // 具体的には、以下のように変更します。

      fetch('http://mashimashikun.com/AccountMGT/server/server.php?action=sendNotification', {
        method: 'POST',
        body: JSON.stringify({
          subscription: subscription,
          targetUrl: "http://mashimashikun.com"
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.ok) {
          console.log('プッシュ通知が送信されました。');
        } else {
          console.error('プッシュ通知の送信に失敗しました。');
        }
      })
      .catch(error => {
        console.error('サーバーへの送信中にエラーが発生しました:', error);
      });
    };
/*
    // 通知の権限をリクエスト
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          // トークンを取得
          return getToken(messaging);
        } else {
          console.error("Unable to get permission to notify.");
        }
      })
      .then((token) => {
        console.log("FCM Token:", token);
        // サーバーにトークンを送信する処理を追加
      })
      .catch((error) => {
        console.error("Error getting notification permission:", error);
      });
*/
    init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Routes>
            {/* デフォルトルートを追加 */}
            
            {/*
                フロントページ
            */}
            <Route path="/nst_tools" element={<RequestForm />} />
            <Route path="/ea_form" element={<RequestFormEA />} />
            <Route path="/ea_hachi" element={<RequestFormEAHACHI />} />
            
            <Route path="/ea_form_ninetails" element={<RequestFormEANT />} />
            <Route path="/hk_lottool" element={<RequestFormCal />} />{/*uidがある場合は画面遷移せず、ツール受け取れる*/}
            <Route path="/trial_hk_lottool" element={<RequestFormCalTrial />} />
            <Route path="/leofxtool" element={<RequestFormLeofx />} />
            <Route path="/paidhachi" element={<RequestFormPaidHachi />} />

            <Route path="/thanks" element={<Thanks />} />
            <Route path="/thanksea" element={<ThanksEA />} />
            <Route path="/thankseaninetails" element={<ThanksEANT />} />
            <Route path="/thankseahachi" element={<ThanksEAHACHI />} />
            <Route path="/thankslottool" element={<ThanksLotTool />} />
            <Route path="/thankslottoolTrial" element={<ThanksLotToolTrial />} />
            <Route path="/thanksleofx" element={<ThanksLeoFX />} />
            <Route path="/thankspaidhachi" element={<ThanksPaidHachiForm />} />

            <Route path="/sorry" element={<Sorry />} />
            <Route path="/sorrylottoolTrial" element={<SorryLotToolTrial />} />

            <Route path="/errorPage" element={<ErrorPage />} />
            <Route path="/developerPage" element={<DeveloperPage />} />

            {/*
                管理者ダッシュボードのルート
            */}
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/salesAnalysis" element={<MonthlySalesSegmentAnalysis />} />

            {/*
                メールフォームと配信解除フォームのルーティング
            */}
            <Route path="/mail-form" element={<MailFormFromHakaseEA />} />
            <Route path="/unsubscribe" element={<MailUnsubscribe />} />

            {/*
                ユーティリティ
            */}
            <Route path="/hachilotcal" element={<HachiLotCal />} />

            {/*
                404エラーページ
            */}
            <Route path="*" element={<ErrorPage />} />

            {/*
                支払い管理ページのルート
            */}
            <Route path="/paymentManagement" element={<PaymentManagementLEO />} />

            {/*
                PayPal管理（管理者専用）
            */}
            <Route path="/paypal-auth" element={<PayPalOAuth />} />
            <Route path="/paypal-success" element={<PayPalSuccess />} />
            <Route path="/paypal-error" element={<PayPalError />} />
            <Route path="/paypal-dev-auth" element={<PayPalDevAuth />} /> {/* 管理者専用: PayPalアプリケーション登録 */}
            <Route path="/paypal-secret-input" element={<PayPalSecretInput />} /> {/* 管理者専用: PayPalシークレット入力 */}
            <Route path="/paypal-subscriptions" element={<PayPalSubscriptionDashboard />} /> {/* 管理者専用: サブスクリプション管理 */}
            
            {/*
                新しいサブスクリプション管理ページのルート
            */}
            <Route path="/subscription-management" element={<SubscriptionManagementDashboard />} /> {/* 管理者専用: MySQLを使ったサブスクリプション管理 */}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;