import React from "react";
import styled from '@emotion/styled';
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
import Font from "../Style/font.css";
import SorryIMG from "../images/sorry.jpg";
import Button from '@mui/material/Button';

const styles = {
  pcDrawer: {
    display: 'block',
    '@media (min-width: 600px)': {
      display: 'block',
    }
  },
  main: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  ill_area: {},
  ill_image: {
    height: '302px',
    display: 'block',
    width: '100%',
  },
  thanks_msg_bold: {
    display: 'block',
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#333333',
  },
  thanks_msg_regular: {
    display: 'block',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#000000',
    opacity: '0.5',
  },
  button_area: {
    width: '100%',
  },
  marginBottom_16px: {
    marginBottom: '16px',
  },
  marginBottom_32px: {
    marginBottom: '32px',
  },
  marginBottom_64px: {
    marginBottom: '64px',
  }
};

const StyledButton = styled(Button)({
  '&.detail-button': {
    backgroundColor: '#0F56A8',
    color: '#ffffff',
    width: '200px',
    fontSize: '14px',
    borderColor: '#0f56A8',
    borderStyle: 'none',
    fontFamily: 'Noto Sans Japanese',
    textAlign: 'center',
  },
});

class SorryLotToolTrial extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={Font.body}>
        <div className={classes.pcDrawer}>
          <div className={classes.main}>
            <div className={classes.marginBottom_64px} />
            <div className={classes.ill_area}>
              <div className={classes.marginBottom_64px} />
              {/* 🟢 `img` タグで画像を表示 */}
              <img
                src={SorryIMG}
                alt="Sorry"
                className={classes.ill_image}
              />
              <div className={classes.marginBottom_64px} />
              <div className={classes.thanks_msg_bold}>
                申し訳ありません。<br />既に申請済の為、再申請出来ません
              </div>
              <div className={classes.marginBottom_16px} />
              <div className={classes.thanks_msg_regular}>
                以前、口座登録をされております。
              </div>
              <div className={classes.thanks_msg_regular}>
                その為、ツールの無料トライアルの利用が出来ません。
              </div>
              <div className={classes.thanks_msg_regular}>
                ツールの利用延長は公式LINEまでお問い合わせください
              </div>
              <div className={classes.marginBottom_64px} />
              <div className={classes.button_area}>
                <StyledButton
                  variant="contained"
                  className="detail-button"
                  onClick={() =>
                    window.open(
                      "https://mxm3416i.autosns.app/addfriend/s/uOPXY9NpH8/@905lbphy"
                    )
                  }
                >
                  公式LINEはコチラ
                </StyledButton>
              </div>
              <div className={classes.marginBottom_32px} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SorryLotToolTrial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SorryLotToolTrial);