import React from "react";
import styled from '@emotion/styled';
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { useNavigate, useLocation } from 'react-router-dom'; // 追加
import ThanksIMG from "../images/thanks.svg";
import Button from '@mui/material/Button';

const styles = theme => ({
  pcDrawer: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  main: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  ill_image: {
    height: '302px',
    display: 'block',
    width: '100%',
  },
  thanks_msg_bold: {
    display: 'block',
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    color: '#333333',
  },
  thanks_msg_regular: {
    display: 'block',
    fontSize: '20px',
    textAlign: 'center',
    color: '#000000',
    opacity: '0.5',
  },
  button_area: {
    width: '100%',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#0F56A8',
  color: '#ffffff',
  width: '200px',
  fontSize: '14px',
  borderColor: '#0f56A8',
  borderStyle: 'none',
  '&:hover': {
    backgroundColor: '#0d3e80',
  },
});

// ✅ Hookをクラスコンポーネントに渡すためのラッパー
function ThanksEANTWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <ThanksEANT {...props} navigate={navigate} location={location} />;
}

class ThanksEANT extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnclick = this.handleOnclick.bind(this);
  }

  handleOnclick() {
    const { navigate, location } = this.props;

    // クエリパラメータの取得
    const params = new URLSearchParams(location.search);
    const id = params.get('id') || '';
    const extra = params.get('extra') || '';

    if (!id) {
      navigate('/ea_form_ninetails');
    } else {
      navigate(`/ea_form_ninetails?id=${id}&extra=${extra}`);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pcDrawer}>
        <div className={classes.main}>
          <img
            src={ThanksIMG}
            className={classes.ill_image}
            alt="Thanks"
          />
          <div className={classes.thanks_msg_bold}>申請ありがとうございます</div>
          <div className={classes.thanks_msg_regular}>入力いただいたメールアドレス宛にツールを送付させていただきます。</div>
          <div className={classes.thanks_msg_regular}>迷惑メールに入っている可能性もございます。</div>
          <div className={classes.thanks_msg_regular}>ご確認の程よろしくお願い致します。</div>
          <div className={classes.button_area}>
            <StyledButton
              variant="contained"
              onClick={this.handleOnclick}
            >
              申請画面に戻る
            </StyledButton>
          </div>
        </div>
      </div>
    );
  }
}

ThanksEANT.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ThanksEANTWrapper);