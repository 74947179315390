import React, { useEffect, useState } from 'react';
import { Container, Card, Alert, Button, Spinner } from 'react-bootstrap';
import { FaCheckCircle, FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './PayPalSuccess.css';

const PayPalSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({
    success: false,
    message: '',
    error: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    // トークンステータスを確認
    const checkTokenStatus = async () => {
      try {
        const response = await fetch('/AccountMGT/server/paypal-token-status.php');
        
        if (!response.ok) {
          throw new Error(`サーバーエラー: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          setStatus({
            success: true,
            message: 'PayPalとの連携が正常に完了しました',
            error: ''
          });
        } else {
          setStatus({
            success: false,
            message: '',
            error: data.error || 'トークンの検証に失敗しました'
          });
        }
      } catch (err) {
        setStatus({
          success: false,
          message: '',
          error: `エラーが発生しました: ${err.message}`
        });
      } finally {
        setLoading(false);
      }
    };

    // 少し遅延してからステータスチェック（視覚的効果のため）
    const timer = setTimeout(() => {
      checkTokenStatus();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleGoHome = () => {
    navigate('/');
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <Card className="shadow-sm success-card">
          <Card.Body className="p-5">
            <Spinner animation="border" variant="primary" />
            <p className="mt-3">PayPalとの連携状態を確認しています...</p>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      <Card className={`shadow-sm success-card ${status.success ? 'success-card-success' : 'success-card-error'}`}>
        <Card.Body className="p-5 text-center">
          {status.success ? (
            <>
              <div className="success-icon-wrapper mb-4">
                <FaCheckCircle className="success-icon" />
              </div>
              <h2 className="mb-4">連携完了</h2>
              <p className="mb-4">{status.message}</p>
              <p className="text-muted mb-4">
                PayPalアカウントとの接続が確立されました。サブスクリプションの管理が可能になりました。
              </p>
              <div className="d-grid gap-2">
                <Button 
                  variant="primary" 
                  size="lg" 
                  onClick={handleGoHome}
                  className="mb-3"
                >
                  <FaArrowLeft className="me-2" />
                  ダッシュボードへ戻る
                </Button>
                <Link to="/paypal-dashboard" className="btn btn-outline-primary">
                  PayPalダッシュボードを表示
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="error-icon-wrapper mb-4">
                <i className="bi bi-exclamation-triangle-fill error-icon"></i>
              </div>
              <h2 className="mb-4">エラーが発生しました</h2>
              <Alert variant="danger" className="mb-4">
                {status.error}
              </Alert>
              <p className="text-muted mb-4">
                PayPalとの連携中に問題が発生しました。もう一度試すか、管理者にお問い合わせください。
              </p>
              <div className="d-grid gap-2">
                <Button 
                  variant="primary" 
                  size="lg" 
                  onClick={handleGoHome}
                  className="mb-3"
                >
                  <FaArrowLeft className="me-2" />
                  ダッシュボードへ戻る
                </Button>
                <Link to="/paypal-auth" className="btn btn-outline-primary">
                  再度連携する
                </Link>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PayPalSuccess; 