import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './SALESAnalysis.css'; // CSSファイルをインポート
import axios from 'axios';

// カードコンポーネント
const Card = ({ children, className }) => (
  <div className={`bg-white rounded-lg border shadow-sm ${className || ''}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="p-4 border-b">
    {children}
  </div>
);

const CardTitle = ({ children }) => (
  <h3 className="text-lg font-medium">{children}</h3>
);

const CardDescription = ({ children }) => (
  <p className="text-sm text-gray-500">{children}</p>
);

const CardContent = ({ children }) => (
  <div className="p-4">
    {children}
  </div>
);

// セレクトコンポーネント
const Select = ({ value, onValueChange, children }) => (
  <select 
    value={value} 
    onChange={(e) => onValueChange(e.target.value)}
    className="w-full border rounded-md p-2 text-sm"
  >
    {children}
  </select>
);

const SelectItem = ({ value, children }) => (
  <option value={value}>{children}</option>
);

// タブコンポーネント
const Tabs = ({ value, onValueChange, children, className }) => (
  <div className={className}>
    {children}
  </div>
);

const TabsList = ({ children, className }) => (
  <div className={`flex border-b mb-4 ${className || ''}`}>
    {children}
  </div>
);

const TabsTrigger = ({ value, children, activeTab, setActiveTab }) => (
  <button
    className={`px-4 py-2 font-medium ${activeTab === value ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
    onClick={() => setActiveTab(value)}
  >
    {children}
  </button>
);

const TabsContent = ({ value, activeTab, children }) => (
  <div className={value === activeTab ? 'block' : 'hidden'}>
    {children}
  </div>
);

const MonthlySalesSegmentAnalysis = () => {
  const [selectedYear, setSelectedYear] = useState('2025');
  const [activeTab, setActiveTab] = useState('overview');
  const [salesData, setSalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // サンプルデータ
  const monthlySalesData = [
    { 
      month: '2024-11', 
      totalList: 11100, 
      firstTimeList: 8000,
      repeatList: 3100,
      firstTimePurchases: 15,
      repeatPurchases: 3,
      totalPurchases: 18,
      conversionRate: 1.73,
      firstTimeConversionRate: 0.19,
      repeatConversionRate: 0.10,
      firstTimeRevenue: 1500000,
      repeatRevenue: 457968,
      totalRevenue: 1957968,
      averagePrice: 97776
    },
    {
      month: '2024-12',
      totalList: 10500,
      firstTimeList: 7500,
      repeatList: 3000,
      firstTimePurchases: 12,
      repeatPurchases: 4,
      totalPurchases: 16,
      conversionRate: 1.52,
      firstTimeConversionRate: 0.16,
      repeatConversionRate: 0.13,
      firstTimeRevenue: 1200000,
      repeatRevenue: 500000,
      totalRevenue: 1700000,
      averagePrice: 106250
    },
    { 
      month: '2025-01', 
      totalList: 11000, 
      firstTimeList: 6000,
      repeatList: 5000,
      firstTimePurchases: 2,
      repeatPurchases: 2,
      totalPurchases: 4,
      conversionRate: 0.36,
      firstTimeConversionRate: 0.03,
      repeatConversionRate: 0.04,
      firstTimeRevenue: 396000,
      repeatRevenue: 396000,
      totalRevenue: 792000,
      averagePrice: 198000
    },
    { 
      month: '2025-02', 
      totalList: 1714, 
      firstTimeList: 500,
      repeatList: 1214,
      firstTimePurchases: 1,
      repeatPurchases: 6,
      totalPurchases: 7,
      conversionRate: 4.09,
      firstTimeConversionRate: 0.20,
      repeatConversionRate: 0.49,
      firstTimeRevenue: 97776,
      repeatRevenue: 586656,
      totalRevenue: 684432,
      averagePrice: 97776
    },
    {
      month: '2025-03',
      totalList: 2500,
      firstTimeList: 1000,
      repeatList: 1500,
      firstTimePurchases: 3,
      repeatPurchases: 8,
      totalPurchases: 11,
      conversionRate: 4.40,
      firstTimeConversionRate: 0.30,
      repeatConversionRate: 0.53,
      firstTimeRevenue: 300000,
      repeatRevenue: 800000,
      totalRevenue: 1100000,
      averagePrice: 100000
    },
    {
      month: '2025-04',
      totalList: 3000,
      firstTimeList: 1200,
      repeatList: 1800,
      firstTimePurchases: 4,
      repeatPurchases: 9,
      totalPurchases: 13,
      conversionRate: 4.33,
      firstTimeConversionRate: 0.33,
      repeatConversionRate: 0.50,
      firstTimeRevenue: 400000,
      repeatRevenue: 900000,
      totalRevenue: 1300000,
      averagePrice: 100000
    }
  ];
  // サーバーからデータを取得する
  
  useEffect(() => {
    const fetchSalesData = async () => {
      setIsLoading(true);
      try {
        var baseUrl = window.location.origin;
        const response = await axios.get(baseUrl+"/AccountMGT/server/sales_data_out.php");
        
        if (response.data.status === 'success') {
          setSalesData(response.data.data);
        } else {
          throw new Error(response.data.message || 'データの取得に失敗しました');
        }
      } catch (err) {
        console.error('データ取得エラー:', err);
        setError(err.message);
        
        // エラー時にサンプルデータを使用（開発用）
        console.log('サンプルデータを使用します');
        setSalesData(monthlySalesData);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSalesData();
  }, []);
  
  // 年のリストを取得
  //テストデータ
  //const years = [...new Set(monthlySalesData.map(item => item.month.substring(0, 4)))];
  const years = salesData.length > 0 
    ? [...new Set(salesData.map(item => item.month.substring(0, 4)))]
    : [];
  // 初期選択年を設定（データがある場合は最新の年、なければデフォルト値）
  useEffect(() => {
    if (years.length > 0 && !selectedYear) {
      // 初期ロード時のみ最新の年を選択
      setSelectedYear(years[years.length - 1]); 
    }
  }, [years, selectedYear]);  
  
  // 選択された年のデータをフィルタリング
  //テストデータ
  //const filteredData = monthlySalesData.filter(item => item.month.startsWith(selectedYear));
  const filteredData = salesData.filter(item => item.month.startsWith(selectedYear));

  // 月名を日本語に変換する関数
  const getMonthName = (monthStr) => {
    const month = parseInt(monthStr.split('-')[1]);
    return `${month}月`;
  };
  
  // データポイントに月名を追加
  const formattedData = filteredData.map(item => ({
    ...item,
    monthName: getMonthName(item.month)
  }));

  // 単位をフォーマットする関数
  const formatNumber = (num) => {
    return new Intl.NumberFormat('ja-JP').format(num);
  };
  
  // 現在の年の合計値を計算
  const yearlyTotals = {
    totalList: filteredData.reduce((sum, item) => sum + item.totalList, 0),
    totalPurchases: filteredData.reduce((sum, item) => sum + item.totalPurchases, 0),
    totalRevenue: filteredData.reduce((sum, item) => sum + item.totalRevenue, 0),
    firstTimeList: filteredData.reduce((sum, item) => sum + item.firstTimeList, 0),
    repeatList: filteredData.reduce((sum, item) => sum + item.repeatList, 0),
    firstTimePurchases: filteredData.reduce((sum, item) => sum + item.firstTimePurchases, 0),
    repeatPurchases: filteredData.reduce((sum, item) => sum + item.repeatPurchases, 0),
    firstTimeRevenue: filteredData.reduce((sum, item) => sum + item.firstTimeRevenue, 0),
    repeatRevenue: filteredData.reduce((sum, item) => sum + item.repeatRevenue, 0)
  };
  
  // 年間平均コンバージョン率
  const yearlyAverageConversionRate = filteredData.length > 0
    ? (yearlyTotals.totalPurchases / yearlyTotals.totalList) * 100
    : 0;
  
  const yearlyFirstTimeConversionRate = filteredData.length > 0 && yearlyTotals.firstTimeList > 0
    ? (yearlyTotals.firstTimePurchases / yearlyTotals.firstTimeList) * 100
    : 0;
    
  const yearlyRepeatConversionRate = filteredData.length > 0 && yearlyTotals.repeatList > 0
    ? (yearlyTotals.repeatPurchases / yearlyTotals.repeatList) * 100
    : 0;
 //テスト時には、コメントアウト
  // ローディング中の表示
  if (isLoading) {
    return (
      <div className="w-full max-w-7xl mx-auto p-6 flex justify-center items-center h-64">
        <div className="text-xl">データを読み込み中...</div>
      </div>
    );
  }

  // エラー表示
  if (error) {
    return (
      <div className="w-full max-w-7xl mx-auto p-6 flex justify-center items-center h-64">
        <div className="text-xl text-red-500">エラー: {error}</div>
      </div>
    );
  }

  // データがない場合の表示
  if (salesData.length === 0) {
    return (
      <div className="w-full max-w-7xl mx-auto p-6 flex justify-center items-center h-64">
        <div className="text-xl">データがありません</div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-7xl mx-auto p-6 space-y-6 bg-slate-50">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">月次セグメント別売上分析</h1>
        
        <div className="w-40">
          <Select value={selectedYear} onValueChange={setSelectedYear}>
            {years.map(year => (
              <SelectItem key={year} value={year}>{year}年</SelectItem>
            ))}
          </Select>
        </div>
      </div>
      
      {/* 年間サマリーカード */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>{selectedYear}年 最初のページへの反応数</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{formatNumber(yearlyTotals.totalList)}</div>
            <div className="text-sm mt-2">
              <div>初回: {formatNumber(yearlyTotals.firstTimeList)}</div>
              <div>再セールス: {formatNumber(yearlyTotals.repeatList)}</div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle>{selectedYear}年 総購入数</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{formatNumber(yearlyTotals.totalPurchases)}</div>
            <div className="text-sm mt-2">
              <div>初回: {formatNumber(yearlyTotals.firstTimePurchases)}</div>
              <div>再セールス: {formatNumber(yearlyTotals.repeatPurchases)}</div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle>{selectedYear}年 平均コンバージョン率</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{yearlyAverageConversionRate.toFixed(2)}%</div>
            <div className="text-sm mt-2">
              <div>初回: {yearlyFirstTimeConversionRate.toFixed(2)}%</div>
              <div>再セールス: {yearlyRepeatConversionRate.toFixed(2)}%</div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle>{selectedYear}年 総売上</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">¥{formatNumber(yearlyTotals.totalRevenue)}</div>
            <div className="text-sm mt-2">
              <div>初回: ¥{formatNumber(yearlyTotals.firstTimeRevenue)}</div>
              <div>再セールス: ¥{formatNumber(yearlyTotals.repeatRevenue)}</div>
            </div>
          </CardContent>
        </Card>
      </div>
      
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid grid-cols-2 w-full">
          <TabsTrigger value="overview" activeTab={activeTab} setActiveTab={setActiveTab}>全体概要</TabsTrigger>
          <TabsTrigger value="segment" activeTab={activeTab} setActiveTab={setActiveTab}>セグメント分析</TabsTrigger>
        </TabsList>
        
        {/* 全体概要タブ */}
        <TabsContent value="overview" activeTab={activeTab}>
          {/* 月次売上と購入率グラフ */}
          <Card>
            <CardHeader>
              <CardTitle>月次売上とコンバージョン率</CardTitle>
              <CardDescription>{selectedYear}年の月別総合データ</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <YAxis yAxisId="right" orientation="right" domain={[0, 'dataMax + 0.5']} />
                    <Tooltip 
                      formatter={(value, name) => {
                        if (name === "売上") return [`¥${formatNumber(value)}`, name];
                        if (name === "コンバージョン率") return [`${value}%`, name];
                        return [value, name];
                      }}
                    />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="totalRevenue" 
                      name="売上" 
                      stroke="#2563eb" 
                      strokeWidth={2}
                    />
                    <Line 
                      yAxisId="right"
                      type="monotone" 
                      dataKey="conversionRate" 
                      name="コンバージョン率" 
                      stroke="#dc2626" 
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          
          {/* 月別最初のページへの反応数と購入数の棒グラフ */}
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>月別最初のページへの反応数と購入数</CardTitle>
              <CardDescription>{selectedYear}年の月別データ</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <Tooltip 
                      formatter={(value, name) => {
                        if (name === "最初のページへの反応数") return [formatNumber(value), name];
                        if (name === "購入数") return [formatNumber(value), name];
                        return [value, name];
                      }}
                    />
                    <Legend />
                    <Bar dataKey="totalList" name="最初のページへの反応数" fill="#4f46e5" />
                    <Bar dataKey="totalPurchases" name="購入数" fill="#f97316" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          
          {/* 月別データテーブル */}
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>月別データ詳細</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border p-2 text-left">月</th>
                      <th className="border p-2 text-right">最初のページへの反応数</th>
                      <th className="border p-2 text-right">購入数</th>
                      <th className="border p-2 text-right">コンバージョン率</th>
                      <th className="border p-2 text-right">平均単価</th>
                      <th className="border p-2 text-right">売上金額</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formattedData.map((item, index) => (
                      <tr key={index} className={index % 2 ? "bg-gray-50" : ""}>
                        <td className="border p-2">{item.monthName}</td>
                        <td className="border p-2 text-right">{formatNumber(item.totalList)}</td>
                        <td className="border p-2 text-right">{formatNumber(item.totalPurchases)}</td>
                        <td className="border p-2 text-right">{item.conversionRate.toFixed(2)}%</td>
                        <td className="border p-2 text-right">¥{formatNumber(Math.round(item.totalRevenue / item.totalPurchases))}</td>
                        <td className="border p-2 text-right">¥{formatNumber(item.totalRevenue)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* セグメント分析タブ */}
        <TabsContent value="segment" activeTab={activeTab}>
          {/* 初回vs再セールスの最初のページへの反応数比較 */}
          <Card>
            <CardHeader>
              <CardTitle>初回vs再セールス 最初のページへの反応数比較</CardTitle>
              <CardDescription>{selectedYear}年の月別セグメントデータ</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <Tooltip formatter={(value) => [formatNumber(value), '最初のページへの反応数']} />
                    <Legend />
                    <Bar dataKey="firstTimeList" name="初回セールス" stackId="a" fill="#2563eb" />
                    <Bar dataKey="repeatList" name="再セールス" stackId="a" fill="#4f46e5" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          {/* 初回vs再セールスのコンバージョン率比較 */}
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>初回vs再セールス コンバージョン率比較</CardTitle>
              <CardDescription>{selectedYear}年の月別セグメントデータ</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthName" />
                    <YAxis domain={[0, 'dataMax + 0.5']} />
                    <Tooltip formatter={(value) => [`${value.toFixed(2)}%`, 'コンバージョン率']} />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="firstTimeConversionRate" 
                      name="初回セールス" 
                      stroke="#2563eb" 
                      strokeWidth={2}
                      dot={{ r: 5 }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="repeatConversionRate" 
                      name="再セールス" 
                      stroke="#dc2626" 
                      strokeWidth={2}
                      dot={{ r: 5 }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="conversionRate" 
                      name="全体" 
                      stroke="#16a34a" 
                      strokeWidth={2}
                      dot={{ r: 5 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          
          {/* 初回vs再セールスの売上比較 */}
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>初回vs再セールス 売上比較</CardTitle>
              <CardDescription>{selectedYear}年の月別セグメントデータ</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthName" />
                    <YAxis />
                    <Tooltip 
                      formatter={(value) => [`¥${formatNumber(value)}`, '売上']} 
                    />
                    <Legend />
                    <Bar dataKey="firstTimeRevenue" name="初回セールス" stackId="a" fill="#2563eb" />
                    <Bar dataKey="repeatRevenue" name="再セールス" stackId="a" fill="#dc2626" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
          
          {/* セグメント別詳細テーブル */}
          <Card className="mt-6">
            <CardHeader>
              <CardTitle>セグメント別詳細データ</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border p-2 text-left" rowSpan="2">月</th>
                      <th className="border p-2 text-center" colSpan="3">初回セールス</th>
                      <th className="border p-2 text-center" colSpan="3">再セールス</th>
                    </tr>
                    <tr className="bg-gray-100">
                      <th className="border p-2 text-right">最初のページへの反応数</th>
                      <th className="border p-2 text-right">コンバージョン率</th>
                      <th className="border p-2 text-right">売上</th>
                      <th className="border p-2 text-right">最初のページへの反応数</th>
                      <th className="border p-2 text-right">コンバージョン率</th>
                      <th className="border p-2 text-right">売上</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formattedData.map((item, index) => (
                      <tr key={index} className={index % 2 ? "bg-gray-50" : ""}>
                        <td className="border p-2">{item.monthName}</td>
                        <td className="border p-2 text-right">{formatNumber(item.firstTimeList)}</td>
                        <td className="border p-2 text-right">{item.firstTimeConversionRate.toFixed(2)}%</td>
                        <td className="border p-2 text-right">¥{formatNumber(item.firstTimeRevenue)}</td>
                        <td className="border p-2 text-right">{formatNumber(item.repeatList)}</td>
                        <td className="border p-2 text-right">{item.repeatConversionRate.toFixed(2)}%</td>
                        <td className="border p-2 text-right">¥{formatNumber(item.repeatRevenue)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default MonthlySalesSegmentAnalysis;