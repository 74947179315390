import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Alert, InputGroup, Spinner } from 'react-bootstrap';
import { FaKey, FaLock, FaUnlock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './PayPalDevAuth.css';

const PayPalDevAuth = () => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const navigate = useNavigate();

  // 管理者のみアクセス可能にする
  useEffect(() => {
    const adminAuth = localStorage.getItem('adminAuth');
    if (adminAuth === 'true') {
      setIsAdmin(true);
    }
  }, []);

  // 管理者認証
  const handleAdminAuth = (e) => {
    e.preventDefault();
    if (adminPassword === 'admin123') { // 簡易的な認証（本番環境では強化が必要）
      setIsAdmin(true);
      localStorage.setItem('adminAuth', 'true');
    } else {
      setError('管理者パスワードが正しくありません');
    }
  };

  // クライアント認証情報を保存して、アクセストークンを直接取得する
  const handleSaveCredentials = async (e) => {
    e.preventDefault();
    
    if (!clientId || !clientSecret) {
      setError('クライアントIDとクライアントシークレットを入力してください');
      return;
    }
    
    setIsLoading(true);
    setError('');
    setSuccess('');
    
    try {
      // クライアントIDとシークレットをサーバーに送信
      const response = await fetch('/AccountMGT/server/save-client-credentials.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          client_id: clientId, 
          client_secret: clientSecret 
        }),
      });
      
      if (!response.ok) {
        throw new Error('認証情報の保存に失敗しました');
      }
      
      const data = await response.json();
      
      if (data.success) {
        setSuccess('認証情報が保存され、アクセストークンの取得に成功しました');
        // 3秒後にサブスクリプションダッシュボードに移動
        setTimeout(() => {
          navigate('/paypal-dashboard');
        }, 3000);
      } else {
        throw new Error(data.error || '認証に失敗しました');
      }
    } catch (err) {
      setError(`エラーが発生しました: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // 管理者認証フォーム
  if (!isAdmin) {
    return (
      <Container className="py-5">
        <Card className="shadow-sm admin-auth-card">
          <Card.Body className="p-5">
            <div className="text-center mb-4">
              <FaLock className="auth-icon" />
              <h2 className="mt-2">管理者認証</h2>
            </div>
            
            <Alert variant="info">
              このページは管理者専用です。管理者パスワードを入力してください。
            </Alert>
            
            {error && (
              <Alert variant="danger" className="my-3">
                {error}
              </Alert>
            )}
            
            <Form onSubmit={handleAdminAuth}>
              <Form.Group className="mb-3">
                <Form.Label>管理者パスワード</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaKey />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                    required
                  />
                </InputGroup>
              </Form.Group>
              
              <div className="d-grid gap-2">
                <Button variant="primary" type="submit">
                  認証
                </Button>
                <Button variant="outline-secondary" onClick={() => navigate('/')}>
                  ダッシュボードへ戻る
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  // 認証情報設定フォーム（管理者認証済み）
  return (
    <Container className="py-5">
      <Card className="shadow-sm auth-card">
        <Card.Body className="p-5">
          <div className="text-center mb-4">
            <img 
              src="/AccountMGT/paypal-logo.png" 
              alt="PayPal Logo" 
              className="paypal-logo mb-3" 
              onError={(e) => { e.target.src = 'https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg'; }}
            />
            <h2>PayPal認証情報設定</h2>
          </div>
          
          <Alert variant="info">
            PayPalのDeveloperダッシュボードからアプリケーションのクライアントIDとシークレットを取得し、
            入力してください。Client Credentials Flowによる認証を使用します。
          </Alert>
          
          {error && (
            <Alert variant="danger" className="my-3">
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert variant="success" className="my-3">
              {success}
            </Alert>
          )}
          
          <Form onSubmit={handleSaveCredentials}>
            <Form.Group className="mb-4">
              <Form.Label>PayPalクライアントID</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaKey />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                  placeholder="例: AYSq3RDGsmBLJE-otTkBtM-jBRd1TCQwFf9RGfwddNXWz0uFU9ztymylOhRS"
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                PayPal Developer Dashboardで取得したクライアントIDを入力してください。
              </Form.Text>
            </Form.Group>
            
            <Form.Group className="mb-4">
              <Form.Label>PayPalクライアントシークレット</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaKey />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  placeholder="例: ELJ-7knV_ma-gF..."
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted">
                PayPal Developer Dashboardで取得したクライアントシークレットを入力してください。
              </Form.Text>
            </Form.Group>
            
            <div className="d-grid gap-2">
              <Button 
                variant="primary" 
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    認証中...
                  </>
                ) : (
                  <>認証情報を保存して接続</>
                )}
              </Button>
              <Button variant="outline-secondary" onClick={() => navigate('/')}>
                キャンセル
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PayPalDevAuth; 