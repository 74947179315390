import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { FaPaypal, FaExclamationTriangle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const PayPalError = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('不明なエラーが発生しました');
  
  useEffect(() => {
    // URLからエラーメッセージを取得
    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get('message');
    
    if (message) {
      setErrorMessage(decodeURIComponent(message));
    }
  }, [location]);
  
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="shadow-sm">
            <Card.Header className="bg-danger text-white">
              <div className="d-flex align-items-center">
                <FaExclamationTriangle className="me-2" />
                <h4 className="mb-0">PayPal 連携エラー</h4>
              </div>
            </Card.Header>
            <Card.Body className="p-4 text-center">
              <FaPaypal size={50} className="text-secondary mb-3" />
              
              <Alert variant="danger" className="mb-4">
                {errorMessage}
              </Alert>
              
              <p className="text-muted mb-4">
                PayPal連携中にエラーが発生しました。再度お試しいただくか、別の方法をお試しください。
              </p>
              
              <div className="d-grid gap-3">
                <Button 
                  variant="primary" 
                  onClick={() => navigate('/paypal-dev-auth')}
                >
                  <FaArrowLeft className="me-2" />
                  PayPal認証をやり直す
                </Button>
                
                <Button 
                  variant="outline-secondary" 
                  onClick={() => navigate('/paymentManagement')}
                >
                  支払い管理ページに戻る
                </Button>
              </div>
            </Card.Body>
            <Card.Footer className="text-center text-muted">
              <small>
                問題が解決しない場合は、<a href="https://developer.paypal.com/docs/api/overview/" target="_blank" rel="noopener noreferrer">PayPalのドキュメント</a>を参照するか、
                管理者にお問い合わせください。
              </small>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayPalError; 