import React, { useState, useEffect } from 'react';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RefreshCw, Settings, Bell, DollarSign, Users, Calendar, BarChart2, AlertCircle, Search, Filter } from 'lucide-react';
import './PaymentManagementLEO.css';

// データ定義
// 月次売上データ（新規契約/継続契約区分あり）
const revenueData = [
  { month: '1月', 新規契約: 300000, サブスク継続: 800000, 新規ユーザー数: 75, 継続ユーザー数: 200 },
  { month: '2月', 新規契約: 320000, サブスク継続: 850000, 新規ユーザー数: 80, 継続ユーザー数: 212 },
  { month: '3月', 新規契約: 350000, サブスク継続: 910000, 新規ユーザー数: 88, 継続ユーザー数: 228 },
  { month: '4月', 新規契約: 380000, サブスク継続: 970000, 新規ユーザー数: 95, 継続ユーザー数: 243 },
  { month: '5月', 新規契約: 400000, サブスク継続: 1050000, 新規ユーザー数: 100, 継続ユーザー数: 262 },
  { month: '6月', 新規契約: 370000, サブスク継続: 1030000, 新規ユーザー数: 93, 継続ユーザー数: 258 }
];

// 決済リンクデータ
const paymentLinkData = [
  { id: 1, name: 'ベーシックプラン', revenue: 980000, users: 350, platform: 'PayPal' },
  { id: 2, name: 'プロフェッショナルプラン', revenue: 1250000, users: 250, platform: 'Stripe' },
  { id: 3, name: 'エンタープライズプラン', revenue: 950000, users: 95, platform: 'UnivaPay' },
  { id: 4, name: '年間プラン', revenue: 1750000, users: 175, platform: 'PayPal' }
];

// 月ごとの退会者数データ
const monthlyChurnData = [
  { month: '1月', churn: 10 },
  { month: '2月', churn: 15 },
  { month: '3月', churn: 20 },
  { month: '4月', churn: 25 },
  { month: '5月', churn: 30 },
  { month: '6月', churn: 35 }
];

// 継続期間別の離脱データ
const churnByPeriodData = [
  { period: '1~3ヶ月', count: 145 },
  { period: '4~6ヶ月', count: 87 },
  { period: '7~12ヶ月', count: 63 },
  { period: '13ヶ月以上', count: 28 }
];

// KPI情報
const kpiData = {
  totalRevenue: 4930000,
  totalUsers: 870,
  averageRetention: 8.5,
  medianRetention: 7,
  ltv: 56800,
  churnRate: 3.8
};

// 未払いユーザーデータ
const unpaidUsers = [
  { id: 1, email: 'user1@example.com', lineUid: 'U123456789a', plan: 'ベーシックプラン', status: '退会申請済み', platform: 'PayPal', amount: 3980, months: 12, contractDate: '2022-06-15', lastPaymentDate: '2023-05-15' },
  { id: 2, email: 'user2@example.com', lineUid: 'U123456789b', plan: 'プロフェッショナルプラン', status: '未払い', platform: 'Stripe', amount: 9800, months: 5, contractDate: '2022-12-03', lastPaymentDate: '2023-04-03' },
  { id: 3, email: 'user3@example.com', lineUid: 'U123456789c', plan: 'ベーシックプラン', status: '未払い', platform: 'PayPal', amount: 3980, months: 3, contractDate: '2023-02-20', lastPaymentDate: '2023-04-20' },
  { id: 4, email: 'user4@example.com', lineUid: 'U123456789d', plan: 'エンタープライズプラン', status: '退会申請済み', platform: 'UnivaPay', amount: 29800, months: 24, contractDate: '2021-05-10', lastPaymentDate: '2023-04-10' },
  { id: 5, email: 'user5@example.com', lineUid: 'U123456789e', plan: 'ベーシックプラン', status: '未払い', platform: 'Stripe', amount: 3980, months: 6, contractDate: '2022-11-08', lastPaymentDate: '2023-04-08' }
];

// 継続率予測データ（今後6ヶ月）
const retentionPredictionData = [
  { month: '7月', 既存ユーザー: 258, 必要新規獲得: 42, 目標ユーザー数: 300 },
  { month: '8月', 既存ユーザー: 248, 必要新規獲得: 52, 目標ユーザー数: 300 },
  { month: '9月', 既存ユーザー: 238, 必要新規獲得: 62, 目標ユーザー数: 300 },
  { month: '10月', 既存ユーザー: 229, 必要新規獲得: 71, 目標ユーザー数: 300 },
  { month: '11月', 既存ユーザー: 220, 必要新規獲得: 80, 目標ユーザー数: 300 },
  { month: '12月', 既存ユーザー: 212, 必要新規獲得: 88, 目標ユーザー数: 300 }
];

// ダミーデータの定義
const dummyData = {
  success: true,
  data: {
    revenueData: [
      { period_label: '1月', new_revenue: 300000, renewal_revenue: 800000, new_users: 75, renewal_users: 200 },
      { period_label: '2月', new_revenue: 320000, renewal_revenue: 850000, new_users: 80, renewal_users: 212 },
      { period_label: '3月', new_revenue: 350000, renewal_revenue: 910000, new_users: 88, renewal_users: 228 },
      { period_label: '4月', new_revenue: 380000, renewal_revenue: 970000, new_users: 95, renewal_users: 243 },
      { period_label: '5月', new_revenue: 400000, renewal_revenue: 1050000, new_users: 100, renewal_users: 262 },
      { period_label: '6月', new_revenue: 370000, renewal_revenue: 1030000, new_users: 93, renewal_users: 258 }
    ],
    paymentLinkData: [
      { id: 1, name: 'ベーシックプラン', revenue: 980000, users: 350, platform: 'PayPal' },
      { id: 2, name: 'プロフェッショナルプラン', revenue: 1250000, users: 250, platform: 'Stripe' },
      { id: 3, name: 'エンタープライズプラン', revenue: 950000, users: 95, platform: 'UnivaPay' },
      { id: 4, name: '年間プラン', revenue: 1750000, users: 175, platform: 'PayPal' }
    ],
    monthlyChurnData: [
      { month: '1月', churn: 10 },
      { month: '2月', churn: 15 },
      { month: '3月', churn: 20 },
      { month: '4月', churn: 25 },
      { month: '5月', churn: 30 },
      { month: '6月', churn: 35 }
    ],
    churnByPeriodData: [
      { period: '1~3ヶ月', count: 145 },
      { period: '4~6ヶ月', count: 87 },
      { period: '7~12ヶ月', count: 63 },
      { period: '13ヶ月以上', count: 28 }
    ],
    kpiData: {
      totalRevenue: 4930000,
      totalUsers: 870,
      averageRetention: 8.5,
      medianRetention: 7,
      ltv: 56800,
      churnRate: 3.8
    },
    unpaidUsers: [
      { id: 1, email: 'user1@example.com', lineUid: 'U123456789a', plan: 'ベーシックプラン', status: '退会申請済み', platform: 'PayPal', amount: 3980, months: 12, contractDate: '2022-06-15', lastPaymentDate: '2023-05-15' },
      { id: 2, email: 'user2@example.com', lineUid: 'U123456789b', plan: 'プロフェッショナルプラン', status: '未払い', platform: 'Stripe', amount: 9800, months: 5, contractDate: '2022-12-03', lastPaymentDate: '2023-04-03' }
    ],
    retentionPredictionData: [
      { month: '7月', value: 258 },
      { month: '8月', value: 248 },
      { month: '9月', value: 238 },
      { month: '10月', value: 229 },
      { month: '11月', value: 220 },
      { month: '12月', value: 212 }
    ]
  }
};

// 売上分析コンポーネント
const RevenueAnalysis = ({ revenueData, paymentLinkData, retentionPredictionData, loading }) => {
  const [targetUsers, setTargetUsers] = useState(300);
  const [retentionRate, setRetentionRate] = useState(96.2);
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [customDateRange, setCustomDateRange] = useState({
    start: null,
    end: null
  });
  
  // fetchAnalyticsData関数を修正
  const fetchAnalyticsData = async (period, dateRange) => {
    try {
      const baseUrl = window.location.hostname.includes('mashimashikun.com') ? '/AccountMGT' : '';
      const apiUrl = `${baseUrl}/server/api/payment-analytics.php?dataType=revenue&period=${period}`;
      
      const url = dateRange ? 
        `${apiUrl}&startDate=${dateRange.start}&endDate=${dateRange.end}` : 
        apiUrl;
      
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`APIレスポンスエラー: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (!data.success) {
        console.warn('APIから失敗レスポンス:', data.message);
        return;
      }
      
      // データの更新処理は親コンポーネントで行うため、
      // ここではデータの取得のみを行う
      console.log('売上データ取得完了:', data);
      
    } catch (error) {
      console.error('売上データ取得エラー:', error);
      // エラー時はダミーデータを使用
      console.log('エラーのためダミーデータを使用');
    }
  };
  
  // 期間選択UI
  const PeriodSelector = () => (
    <div className="flex flex-col space-y-4 mb-6">
      <div className="flex items-center space-x-4">
        <div className="flex rounded-lg shadow-sm">
          <button
            onClick={() => {
              setSelectedPeriod('week');
              setCustomDateRange({ start: null, end: null });
              fetchAnalyticsData('week');
            }}
            className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
              selectedPeriod === 'week' && !customDateRange.start
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            週間
          </button>
          <button
            onClick={() => {
              setSelectedPeriod('month');
              setCustomDateRange({ start: null, end: null });
              fetchAnalyticsData('month');
            }}
            className={`px-4 py-2 text-sm font-medium ${
              selectedPeriod === 'month' && !customDateRange.start
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            月間
          </button>
          <button
            onClick={() => {
              setSelectedPeriod('year');
              setCustomDateRange({ start: null, end: null });
              fetchAnalyticsData('year');
            }}
            className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
              selectedPeriod === 'year' && !customDateRange.start
                ? 'bg-blue-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            年間
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="date"
            className="px-3 py-2 border border-gray-300 rounded-md text-sm"
            value={customDateRange.start || ''}
            onChange={(e) => setCustomDateRange(prev => ({ ...prev, start: e.target.value }))}
          />
          <span className="text-gray-500">〜</span>
          <input
            type="date"
            className="px-3 py-2 border border-gray-300 rounded-md text-sm"
            value={customDateRange.end || ''}
            onChange={(e) => setCustomDateRange(prev => ({ ...prev, end: e.target.value }))}
          />
          <button
            onClick={() => {
              if (customDateRange.start && customDateRange.end) {
                setSelectedPeriod('custom');
                fetchAnalyticsData('custom', customDateRange);
              }
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm hover:bg-blue-700"
          >
            適用
          </button>
        </div>
      </div>
      <div className="text-sm text-gray-500">
        表示期間: {customDateRange.start && customDateRange.end 
          ? `${customDateRange.start} 〜 ${customDateRange.end}`
          : selectedPeriod === 'week' ? '過去7日間' 
          : selectedPeriod === 'month' ? '過去30日間' 
          : '過去1年間'}
      </div>
    </div>
  );

  // 売上推移グラフ
  const RevenueChart = () => (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-900">
          {selectedPeriod === 'custom' ? '期間指定' : 
           selectedPeriod === 'week' ? '週次' : 
           selectedPeriod === 'month' ? '月次' : '年次'}売上推移
        </h3>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {/* データのエクスポート処理 */}}
            className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
          >
            エクスポート
          </button>
          <button
            onClick={() => {/* データの印刷処理 */}}
            className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
          >
            印刷
          </button>
        </div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={revenueData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="period_label" 
              tick={{ fontSize: 12 }}
              angle={-45}
              textAnchor="end"
              height={60}
            />
            <YAxis />
            <Tooltip 
              formatter={(value) => value.toLocaleString() + '円'}
              labelFormatter={(label) => `期間: ${label}`}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="new_revenue" 
              name="新規契約" 
              stroke="#ff7300" 
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
            <Line 
              type="monotone" 
              dataKey="renewal_revenue" 
              name="サブスク継続" 
              stroke="#8884d8"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  // 売上詳細表
  const RevenueTable = () => (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium text-gray-700">
          {selectedPeriod === 'custom' ? '期間指定' : 
           selectedPeriod === 'week' ? '週次' : 
           selectedPeriod === 'month' ? '月次' : '年次'}売上詳細
        </h3>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {/* テーブルのエクスポート処理 */}}
            className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
          >
            エクスポート
          </button>
          <button
            onClick={() => {/* テーブルの印刷処理 */}}
            className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
          >
            印刷
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                期間
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                新規契約
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                新規ユーザー数
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                サブスク継続
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                継続ユーザー数
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                合計
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                総ユーザー数
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {revenueData.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {item.period_label}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.new_revenue.toLocaleString()}円
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.new_users}人
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.renewal_revenue.toLocaleString()}円
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {item.renewal_users}人
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {(item.new_revenue + item.renewal_revenue).toLocaleString()}円
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {item.new_users + item.renewal_users}人
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  // 継続率変更時の予測データ再計算
  const calculatePrediction = (baseUsers, rate, target) => {
    const monthlyRate = rate / 100;
    const result = [];
    let currentUsers = baseUsers;
    
    for (let i = 0; i < 6; i++) {
      const month = new Date();
      month.setMonth(month.getMonth() + i + 1);
      const monthName = month.toLocaleString('ja-JP', { month: 'long' });
      
      currentUsers = Math.round(currentUsers * monthlyRate);
      const neededNewUsers = Math.max(0, target - currentUsers);
      
      result.push({
        month: monthName,
        既存ユーザー: currentUsers,
        必要新規獲得: neededNewUsers,
        目標ユーザー数: target
      });
    }
    
    return result;
  };
  
  // 最新の継続ユーザー数を取得
  const latestUsers = revenueData && revenueData.length > 0 
    ? revenueData[revenueData.length - 1].継続ユーザー数 
    : 0;
  
  // 予測データを計算
  const predictionData = calculatePrediction(latestUsers, retentionRate, targetUsers);
  
  // 継続率変更時に解約率も更新する関数
  const handleRetentionRateChange = (newRate) => {
    setRetentionRate(newRate);
    // ここで解約率の更新をシミュレート（実際のアプリケーションではAPIなどで更新）
    console.log(`解約率が${(100 - newRate).toFixed(1)}%に更新されました`);
  };
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-center">
          <RefreshCw size={32} className="animate-spin mx-auto text-indigo-500 mb-4" />
          <p className="text-gray-500">データ読み込み中...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      <PeriodSelector />
      <RevenueChart />
      <RevenueTable />
      {/* 継続率予測と新規獲得必要数グラフ */}
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">継続率予測と必要新規獲得数</h3>
          <div className="flex items-center space-x-4">
            <div>
              <label htmlFor="targetUsers" className="block text-sm font-medium text-gray-700 mb-1">
                目標ユーザー数
              </label>
              <input
                type="number"
                id="targetUsers"
                className="w-24 py-1 px-2 border border-gray-300 rounded-md text-sm"
                value={targetUsers}
                onChange={(e) => setTargetUsers(Number(e.target.value))}
                min="100"
                max="1000"
              />
            </div>
            <div>
              <label htmlFor="retentionRate" className="block text-sm font-medium text-gray-700 mb-1">
                月次継続率 (%) <span className="text-xs text-gray-500">= 100 - 解約率</span>
              </label>
              <input
                type="number"
                id="retentionRate"
                className="w-24 py-1 px-2 border border-gray-300 rounded-md text-sm"
                value={retentionRate}
                onChange={(e) => handleRetentionRateChange(Number(e.target.value))}
                min="50"
                max="100"
                step="0.1"
              />
            </div>
          </div>
        </div>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={predictionData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip 
                formatter={(value, name) => [
                  `${value}人`, 
                  name === '既存ユーザー' ? '継続ユーザー数' : 
                  name === '必要新規獲得' ? '必要な新規獲得数' : '目標ユーザー数'
                ]}
              />
              <Legend />
              <Bar dataKey="既存ユーザー" stackId="a" fill="#8884d8" name="継続ユーザー数" />
              <Bar dataKey="必要新規獲得" stackId="a" fill="#ff7300" name="必要な新規獲得数" />
              <Line type="monotone" dataKey="目標ユーザー数" stroke="#82ca9d" strokeWidth={2} dot={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-4 p-4 bg-blue-50 rounded-md">
          <p className="text-sm text-blue-800">
            <strong>分析:</strong> 現在の月次継続率({retentionRate.toFixed(1)}%)では、目標ユーザー数({targetUsers}人)を維持するために、
            6ヶ月後には月間<strong>{predictionData[5]?.必要新規獲得 || 0}人</strong>の新規獲得が必要です。
            継続率が1%向上すると、必要な新規獲得数は約<strong>{Math.round((predictionData[5]?.必要新規獲得 || 0) * 0.8)}人</strong>に減少します。
            <br />
            <span className="text-xs mt-2 block">※ 月次継続率 = 100% - 月間解約率({(100 - retentionRate).toFixed(1)}%)</span>
          </p>
        </div>
      </div>

      {/* 決済リンク別売上 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-900 mb-4">決済リンク別売上</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">リンク名</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">プラットフォーム</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ユーザー数</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">売上</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paymentLinkData.map((link) => (
                <tr key={link.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{link.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{link.platform}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{link.users.toLocaleString()}人</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{link.revenue.toLocaleString()}円</td>
                </tr>
              ))}
              <tr className="bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">合計</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {paymentLinkData.reduce((sum, item) => sum + item.users, 0).toLocaleString()}人
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {paymentLinkData.reduce((sum, item) => sum + item.revenue, 0).toLocaleString()}円
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// 離脱分析コンポーネント
const ChurnAnalysis = ({ monthlyChurnData, churnByPeriodData, loading }) => {
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-center">
          <RefreshCw size={32} className="animate-spin mx-auto text-indigo-500 mb-4" />
          <p className="text-gray-500">データ読み込み中...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* 月ごとの退会者数グラフ */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-700 mb-4">月ごとの退会者数</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={monthlyChurnData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip formatter={(value) => `${value}人`} />
              <Legend />
              <Bar dataKey="churn" name="退会者数" fill="#FF6B6B" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* 継続期間別退会者数グラフ */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-700 mb-4">継続期間別退会者数</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={churnByPeriodData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip formatter={(value) => `${value}人`} />
              <Legend />
              <Bar dataKey="count" name="退会者数" fill="#4D96FF" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* 継続期間別退会者表 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-gray-700 mb-4">継続期間別退会者詳細</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">継続期間</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">離脱人数</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">全体比率</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {churnByPeriodData.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.period}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.count}人</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {Math.round(item.count / churnByPeriodData.reduce((sum, i) => sum + i.count, 0) * 100)}%
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">合計</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {churnByPeriodData.reduce((sum, item) => sum + item.count, 0)}人
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// 未払いユーザー管理コンポーネント
const UnpaidUsersManagement = ({ unpaidUsers, loading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all'); // 'all', 'active', 'suspended'
  
  // ステータスフィルタリング用のマッピング
  const statusMapping = {
    'active': '継続',
    'suspended': '未払い'
  };
  
  // 検索フィルタリング
  const filteredUsers = unpaidUsers.filter(user => {
    // まずステータスでフィルタリング
    const statusMatch = statusFilter === 'all' || 
                       (statusFilter === 'active' && user.status === '継続') ||
                       (statusFilter === 'suspended' && user.status === '未払い');
    
    if (!statusMatch) return false;
    
    // 次に検索ワードでフィルタリング
    return searchTerm === '' || 
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.lineName && user.lineName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.lineUid && user.lineUid.toLowerCase().includes(searchTerm.toLowerCase())) ||
      user.plan.toLowerCase().includes(searchTerm.toLowerCase());
  });
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-center">
          <RefreshCw size={32} className="animate-spin mx-auto text-indigo-500 mb-4" />
          <p className="text-gray-500">データ読み込み中...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">未払いユーザー一覧</h3>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search size={16} className="text-gray-400" />
            </div>
            <input
              type="text"
              className="py-2 pl-10 pr-4 block w-full border border-gray-300 rounded-md text-sm"
              placeholder="検索..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex items-center bg-amber-100 text-amber-800 px-3 py-1 rounded-md">
            <AlertCircle size={16} className="mr-2" />
            <span className="text-sm">要対応: {unpaidUsers.length}件</span>
          </div>
        </div>
      </div>
      
      {/* ステータスフィルタータブ */}
      <div className="border-b border-gray-200 mb-4">
        <nav className="flex space-x-8">
          <button
            className={`py-2 px-1 border-b-2 font-medium text-sm ${
              statusFilter === 'all'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setStatusFilter('all')}
          >
            すべて
          </button>
          <button
            className={`py-2 px-1 border-b-2 font-medium text-sm ${
              statusFilter === 'active'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setStatusFilter('active')}
          >
            継続
          </button>
          <button
            className={`py-2 px-1 border-b-2 font-medium text-sm ${
              statusFilter === 'suspended'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setStatusFilter('suspended')}
          >
            未払い
          </button>
        </nav>
      </div>
      
      {unpaidUsers.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-500">未払いユーザーはいません</p>
        </div>
      ) : filteredUsers.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-gray-500">該当するユーザーがいません</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">契約日</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">メールアドレス</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ユーザー名</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">LINE UID</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">LINE名</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">プラン</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ステータス</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">プラットフォーム</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">金額</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">継続月数</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">最終決済日</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">アクション</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.contractDate}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name || '-'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.lineUid === '未取得' ? (
                      <span className="text-red-500">未取得</span>
                    ) : user.lineUid}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.lineName || '-'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.plan}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.status === '未払い' 
                        ? 'bg-red-100 text-red-800' 
                        : user.status === '継続' 
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {user.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.platform}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.amount.toLocaleString()}円</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.months}ヶ月</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.lastPaymentDate}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <button className="text-indigo-600 hover:text-indigo-900">詳細</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// KPIカードコンポーネント
const KpiCards = ({ kpiData, loading }) => {
  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-6 opacity-50">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="bg-white shadow rounded-lg p-5">
            <div className="animate-pulse flex items-center">
              <div className="rounded-md bg-gray-300 h-12 w-12"></div>
              <div className="ml-5 w-0 flex-1">
                <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                <div className="h-6 bg-gray-300 rounded w-1/2"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-6">
      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-indigo-500 rounded-md p-3">
            <DollarSign className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">総売上</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.totalRevenue.toLocaleString()}円</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-green-500 rounded-md p-3">
            <Users className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">総ユーザー数</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.totalUsers.toLocaleString()}人</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-amber-500 rounded-md p-3">
            <DollarSign className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">顧客生涯価値(LTV)</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.ltv.toLocaleString()}円</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-red-500 rounded-md p-3">
            <AlertCircle className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">月間解約率</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.churnRate}%</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-blue-500 rounded-md p-3">
            <Calendar className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">平均継続月数</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.averageRetention}ヶ月</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg p-5">
        <div className="flex items-center">
          <div className="bg-purple-500 rounded-md p-3">
            <BarChart2 className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">中央値継続月数</dt>
              <dd className="text-lg font-medium text-gray-900">{kpiData.medianRetention}ヶ月</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

// メインダッシュボードコンポーネント
const CompleteDashboard = () => {
  const [activeTab, setActiveTab] = useState('revenue');
  const [timeRange, setTimeRange] = useState('month');
  
  // API データをステートで管理
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [revenueData, setRevenueData] = useState([]);
  const [paymentLinkData, setPaymentLinkData] = useState([]);
  const [monthlyChurnData, setMonthlyChurnData] = useState([]);
  const [churnByPeriodData, setChurnByPeriodData] = useState([]);
  const [kpiData, setKpiData] = useState({
    totalRevenue: 0,
    totalUsers: 0,
    averageRetention: 0,
    medianRetention: 0,
    ltv: 0,
    churnRate: 0
  });
  const [unpaidUsers, setUnpaidUsers] = useState([]);
  const [retentionPredictionData, setRetentionPredictionData] = useState([]);
  
  // データ取得関数
  const fetchAnalyticsData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      console.log('APIリクエスト開始: timeRange=', timeRange);
      
      const baseUrl = window.location.hostname.includes('mashimashikun.com') ? '/AccountMGT' : '';
      const apiUrl = `${baseUrl}/server/api/payment-analytics.php?dataType=all&timeRange=${timeRange}`;
      console.log('APIリクエストURL:', apiUrl);
      
      const response = await fetch(apiUrl);
      console.log('APIレスポンスステータス:', response.status);
      
      // レスポンスのContent-Typeをチェック
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('APIからのレスポンスがJSONではありません');
      }
      
      if (!response.ok) {
        throw new Error(`APIレスポンスエラー: ${response.status} - ${response.statusText}`);
      }
      
      // レスポンスをテキストとして取得
      const responseText = await response.text();
      console.log('APIレスポンステキスト:', responseText);
      
      // レスポンスが空の場合のチェック
      if (!responseText.trim()) {
        throw new Error('APIからのレスポンスが空です');
      }
      
      // テキストからJSONへ変換
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (jsonError) {
        console.error('JSONパースエラー:', jsonError);
        console.error('レスポンスの内容:', responseText);
        // エラー時はダミーデータを使用
        console.log('エラーのためダミーデータを使用');
        data = dummyData;
      }
      
      // データの検証
      if (!data || typeof data !== 'object') {
        throw new Error('APIからのレスポンスが不正な形式です');
      }
      
      // サーバーからエラーが返された場合
      if (!data.success) {
        console.warn('APIから失敗レスポンス:', data.message);
        setError(`サーバーからのエラー: ${data.message || 'エラーメッセージがありません'}`);
      }
      
      // データをステートに設定
      setRevenueData(data.data?.revenueData || []);
      setPaymentLinkData(data.data?.paymentLinkData || []);
      setMonthlyChurnData(data.data?.monthlyChurnData || []);
      setChurnByPeriodData(data.data?.churnByPeriodData || []);
      setKpiData(data.data?.kpiData || {
        totalRevenue: 0,
        totalUsers: 0,
        averageRetention: 0,
        medianRetention: 0,
        ltv: 0,
        churnRate: 0
      });
      setUnpaidUsers(data.data?.unpaidUsers || []);
      setRetentionPredictionData(data.data?.retentionPredictionData || []);
      
    } catch (error) {
      console.error('データ取得エラー:', error);
      setError(error.message);
      
      // エラー時にダミーデータを使用
      console.log('エラーのためダミーデータを使用');
      setRevenueData(dummyData.data.revenueData);
      setPaymentLinkData(dummyData.data.paymentLinkData);
      setMonthlyChurnData(dummyData.data.monthlyChurnData);
      setChurnByPeriodData(dummyData.data.churnByPeriodData);
      setKpiData(dummyData.data.kpiData);
      setUnpaidUsers(dummyData.data.unpaidUsers);
      setRetentionPredictionData(dummyData.data.retentionPredictionData);
    } finally {
      setLoading(false);
    }
  };
  
  // コンポーネントマウント時とtimeRange変更時にデータを取得
  useEffect(() => {
    fetchAnalyticsData();
  }, [timeRange]);
  
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* ヘッダー */}
      <header className="bg-white border-b shadow-sm p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold text-indigo-600">SubManager</h1>
          <div className="flex items-center space-x-4">
            <button className="p-2 rounded-full text-gray-400 hover:text-gray-500">
              <Bell size={20} />
            </button>
            <button className="p-2 rounded-full text-gray-400 hover:text-gray-500">
              <Settings size={20} />
            </button>
            <div className="h-8 w-8 rounded-full bg-indigo-500 flex items-center justify-center text-white font-medium">
              A
            </div>
          </div>
        </div>
      </header>

      {/* メインコンテンツ */}
      <div className="flex-1 p-6">
        <div className="max-w-7xl mx-auto">
          {/* 時間範囲選択とリフレッシュボタン */}
          <div className="mb-6 flex justify-between">
            <div className="flex space-x-2">
              <button 
                className={`px-3 py-2 text-sm font-medium rounded-md ${timeRange === 'week' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700'}`}
                onClick={() => setTimeRange('week')}
                disabled={loading}
              >
                週間
              </button>
              <button 
                className={`px-3 py-2 text-sm font-medium rounded-md ${timeRange === 'month' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700'}`}
                onClick={() => setTimeRange('month')}
                disabled={loading}
              >
                月間
              </button>
              <button 
                className={`px-3 py-2 text-sm font-medium rounded-md ${timeRange === 'year' ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700'}`}
                onClick={() => setTimeRange('year')}
                disabled={loading}
              >
                年間
              </button>
            </div>
            <button 
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              onClick={fetchAnalyticsData}
              disabled={loading}
            >
              {loading ? (
                <>
                  <RefreshCw size={16} className="mr-2 animate-spin" />
                  取得中...
                </>
              ) : (
                <>
                  <RefreshCw size={16} className="mr-2" />
                  データ更新
                </>
              )}
            </button>
          </div>
          
          {/* エラーメッセージ */}
          {error && (
            <div className="mb-6 bg-red-50 text-red-700 p-4 rounded-md">
              <p className="font-medium">エラーが発生しました</p>
              <p className="text-sm">{error}</p>
            </div>
          )}
          
          {/* タブナビゲーション */}
          <div className="border-b border-gray-200 mb-6">
            <nav className="flex space-x-8">
              <button
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'revenue'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('revenue')}
              >
                売上分析
              </button>
              <button
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'churn'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('churn')}
              >
                離脱分析
              </button>
              <button
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'unpaid'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
                onClick={() => setActiveTab('unpaid')}
              >
                未払い管理
              </button>
            </nav>
          </div>
          
          {/* KPIカード */}
          <KpiCards kpiData={kpiData} loading={loading} />
          
          {/* タブコンテンツ */}
          {activeTab === 'revenue' && (
            <RevenueAnalysis 
              revenueData={revenueData} 
              paymentLinkData={paymentLinkData} 
              retentionPredictionData={retentionPredictionData}
              loading={loading} 
            />
          )}
          {activeTab === 'churn' && (
            <ChurnAnalysis 
              monthlyChurnData={monthlyChurnData} 
              churnByPeriodData={churnByPeriodData}
              loading={loading}
            />
          )}
          {activeTab === 'unpaid' && (
            <UnpaidUsersManagement 
              unpaidUsers={unpaidUsers}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteDashboard;