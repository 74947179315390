import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, 
  Container, 
  Paper, 
  Tab, 
  Tabs,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';

// スタイル付きコンポーネントの定義
const StyledContainer = styled(Container)({
  marginTop: '32px',
  marginBottom: '32px',
});

const StyledPaper = styled(Paper)({
  padding: '24px',
  borderRadius: '16px',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
});

const SearchField = styled(TextField)({
  marginBottom: '24px',
  width: '300px',
});

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 300px)', // ヘッダーやタブなどの高さを考慮
  '& .MuiTableHead-root': {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
  },
});

// メインコンポーネント
const AdminDashboard = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [emailSearchTerm, setEmailSearchTerm] = useState('');
  const [accountStatus, setAccountStatus] = useState(null);
  const [emailSearchResults, setEmailSearchResults] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [accessCode, setAccessCode] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);

  // 管理者権限コード (実際の実装では環境変数や認証システムを使用すべき)
  const ADMIN_ACCESS_CODE = '1qaz2wsx!';

  useEffect(() => {
    fetchTableData();
    fetchAccountDetails();
  }, []);

  const fetchTableData = async () => {
    setLoading(true);
    try {
      var baseUrl = window.location.origin;
      const response = await axios.get(baseUrl+"/AccountMGT/server/accountData.php"); // ページ1のデータ取得
      setTableData(response.data);
    } catch (error) {
      console.error('データの取得に失敗しました:', error);
    }
    setLoading(false);
  };

  const fetchAccountDetails = async () => {
    setLoading(true);
    try {
      var baseUrl = window.location.origin;
      const response = await axios.get(baseUrl+"/AccountMGT/server/accountDetails.php");
      console.log('Account Details Response:', response.data); // レスポンスの確認用
      setAccountDetails(response.data || []); // データがnullの場合は空配列を設定
    } catch (error) {
      console.error('口座詳細の取得に失敗しました:', error);
      setAccountDetails([]); // エラー時は空配列を設定
    }
    setLoading(false);
  };

  const handleAccountSearch = async () => {
    if (!searchTerm) {
      alert('口座番号を入力してください');
      return;
    }

    setLoading(true);
    try {
      var baseUrl = window.location.origin;
      const params = new URLSearchParams();
      params.append('AccountNumber', searchTerm);
      
      const response = await axios.get(baseUrl + "/AccountMGT/server/accountcheck_json.php", { params });
      setAccountStatus(response.data.result === "true" ? 'approved' : 'not_approved');
    } catch (error) {
      console.error('口座の検索に失敗しました:', error);
      setAccountStatus('error');
    }
    setLoading(false);
  };

  const handleEmailSearch = async () => {
    if (!emailSearchTerm) {
      alert('メールアドレスを入力してください');
      return;
    }

    // 権限チェック
    if (!isAuthorized) {
      setShowAuthDialog(true);
      return;
    }

    setLoading(true);
    setEmailSearchResults([]);

    try {
      var baseUrl = window.location.origin;
      const params = new URLSearchParams();
      params.append('Email', emailSearchTerm);
      
      const response = await axios.get(baseUrl + "/AccountMGT/server/emailsearch_json.php", { params });
      
      if (response.data && Array.isArray(response.data)) {
        setEmailSearchResults(response.data);
      } else {
        console.error('予期しないレスポンス形式:', response.data);
        setEmailSearchResults([]);
      }
    } catch (error) {
      console.error('メールアドレスの検索に失敗しました:', error);
      setEmailSearchResults([]);
    }
    setLoading(false);
  };

  const handleAuthSubmit = () => {
    if (accessCode === ADMIN_ACCESS_CODE) {
      setIsAuthorized(true);
      setShowAuthDialog(false);
      handleEmailSearch(); // 認証成功後に検索を実行
    } else {
      alert('アクセスコードが正しくありません');
    }
  };

  const maskEmail = (email) => {
    if (!email) return '';
    const parts = email.split('@');
    if (parts.length !== 2) return email;
    
    const name = parts[0];
    const domain = parts[1];
    
    // 名前部分を一部マスク
    const maskedName = name.length <= 3 
      ? name.substring(0, 1) + '*'.repeat(name.length - 1)
      : name.substring(0, 2) + '*'.repeat(name.length - 3) + name.substring(name.length - 1);
    
    return `${maskedName}@${domain}`;
  };

  const maskLineUid = (uid) => {
    if (!uid) return '';
    return uid.substring(0, 3) + '*'.repeat(uid.length - 6) + uid.substring(uid.length - 3);
  };

  const showDetailDialog = (row) => {
    setSelectedDetail(row);
    setDetailDialogOpen(true);
  };

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <Box p={3} display="flex" flexDirection="column" alignItems="center">
            <SearchField
              label="メールアドレスを入力（部分検索可）"
              variant="outlined"
              value={emailSearchTerm}
              onChange={(e) => setEmailSearchTerm(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              onClick={handleEmailSearch}
              disabled={loading}
              sx={{ minWidth: 200, mb: 3 }}
            >
              {loading ? <CircularProgress size={24} /> : '検索'}
            </Button>
            
            {emailSearchResults.length > 0 ? (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  検索結果: {emailSearchResults.length}件
                  {!isAuthorized && 
                    <Chip 
                      color="warning" 
                      size="small" 
                      label="個人情報は管理者権限で閲覧可能" 
                      sx={{ ml: 2 }} 
                    />
                  }
                </Typography>
                <StyledTableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>詳細</TableCell>
                        <TableCell>LINE名</TableCell>
                        <TableCell>メールアドレス</TableCell>
                        <TableCell>口座番号</TableCell>
                        <TableCell>招待コード</TableCell>
                        <TableCell>口座確認</TableCell>
                        <TableCell>登録日</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {emailSearchResults.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <IconButton 
                              size="small" 
                              onClick={() => isAuthorized ? showDetailDialog(row) : setShowAuthDialog(true)}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                          <TableCell>{isAuthorized ? row.line_name : (row.line_name?.substring(0, 1) + '***')}</TableCell>
                          <TableCell>{isAuthorized ? row.mail_address : maskEmail(row.mail_address)}</TableCell>
                          <TableCell>{row.account_number}</TableCell>
                          <TableCell>{row.invitation_code}</TableCell>
                          <TableCell>{row.account_check === '1' ? 'あり' : 'なし'}</TableCell>
                          <TableCell>{row.inserted_date}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </>
            ) : (
              emailSearchTerm !== '' && !loading && (
                <Typography color="error" sx={{ mt: 2 }}>
                  検索結果がありません
                </Typography>
              )
            )}
          </Box>
        );
      case 1:
        return (
          <Box p={3}>
            <Typography variant="h6" sx={{ mt: 2 }}>月次データ</Typography>
            <StyledTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>日付</TableCell>
                    <TableCell>Youtube Count</TableCell>
                    <TableCell>LEOFX Count</TableCell>
                    <TableCell>DFX Count</TableCell>
                    <TableCell>NST Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.Youtube_count}</TableCell>
                      <TableCell>{row.LEOFX_count}</TableCell>
                      <TableCell>{row.DFX_count}</TableCell>
                      <TableCell>{row.NST_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        );
      case 2:
        return (
          <Box p={3} display="flex" flexDirection="column" alignItems="center">
            <SearchField
              label="口座番号を入力"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleAccountSearch}
              disabled={loading}
              sx={{ minWidth: 200 }}
            >
              {loading ? <CircularProgress size={24} /> : '検索'}
            </Button>
            {accountStatus && (
              <Typography 
                sx={{ mt: 2 }}
                color={accountStatus === 'approved' ? 'success.main' : 'error.main'}
              >
                {accountStatus === 'approved' 
                  ? '口座は承認済みです。EAのファイルの配布をお願いします。' 
                  : accountStatus === 'error' 
                    ? '検索中にエラーが発生しました。' 
                    : '口座は未承認です。口座開設を確認してください。'}
              </Typography>
            )}
          </Box>
        );
      case 3:
        return (
          <Box p={3}>
            <Typography variant="h6">口座開設の詳細推移</Typography>
            <StyledTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>日付</TableCell>
                    <TableCell>XM</TableCell>
                    <TableCell>HFM</TableCell>
                    <TableCell>BigBoss (その他)</TableCell>
                    <TableCell>BigBoss (UNICORN)</TableCell>
                    <TableCell>BigBoss (Mr.Goto)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(accountDetails) && accountDetails.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.XM_count}</TableCell>
                      <TableCell>{row.HFM_count}</TableCell>
                      <TableCell>{row.BigBoss_NULL_count}</TableCell>
                      <TableCell>{row.BigBoss_unicorn_count}</TableCell>
                      <TableCell>{row.BigBoss_Goto_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <StyledContainer>
      <StyledPaper>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          centered
        >
          <Tab label="メールアドレス検索" />
          <Tab label="ツール・EA受取推移" />
          <Tab label="口座番号検索" />
          <Tab label="口座開設推移" />
        </Tabs>
        {renderContent()}

        {/* 認証ダイアログ */}
        <Dialog open={showAuthDialog} onClose={() => setShowAuthDialog(false)}>
          <DialogTitle>管理者認証</DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              個人情報を閲覧するには管理者権限が必要です。
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              label="アクセスコード"
              type="password"
              fullWidth
              variant="outlined"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAuthDialog(false)}>キャンセル</Button>
            <Button onClick={handleAuthSubmit} variant="contained">確認</Button>
          </DialogActions>
        </Dialog>

        {/* 詳細情報ダイアログ */}
        <Dialog open={detailDialogOpen} onClose={() => setDetailDialogOpen(false)} maxWidth="md">
          <DialogTitle>詳細情報</DialogTitle>
          <DialogContent>
            {selectedDetail && (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">LINE名</TableCell>
                    <TableCell>{selectedDetail.line_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">姓名</TableCell>
                    <TableCell>{selectedDetail.last_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">LINE UID</TableCell>
                    <TableCell>{selectedDetail.uid}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">メールアドレス</TableCell>
                    <TableCell>{selectedDetail.mail_address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">口座番号</TableCell>
                    <TableCell>{selectedDetail.account_number}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">ツールタイプ</TableCell>
                    <TableCell>{selectedDetail.tool_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">EAタイプ</TableCell>
                    <TableCell>{selectedDetail.ea_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">招待コード</TableCell>
                    <TableCell>{selectedDetail.invitation_code}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">登録日</TableCell>
                    <TableCell>{selectedDetail.inserted_date}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetailDialogOpen(false)}>閉じる</Button>
          </DialogActions>
        </Dialog>
      </StyledPaper>
    </StyledContainer>
  );
}

export default AdminDashboard;