import React from "react";
import styled from '@emotion/styled';
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
import Font from "../Style/font.css";
import ThanksIMG from "../images/thanks.svg";

const styles = theme => ({
  pcDrawer: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  main: {
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '100%',
    textAlign: 'center',
  },
  ill_area: {},
  ill_image: {
    height: '302px',
    display: 'block',
    width: '100%',
  },
  thanks_msg_bold: {
    display: 'block',
    fontSize: '28px',
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#333333',
  },
  thanks_msg_regular: {
    display: 'block',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Noto Sans Japanese',
    color: '#000000',
    opacity: '0.5',
  },
  marginBottom_64px: {
    marginBottom: '64px',
  },
});

class ThanksLotToolTrial extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={Font.body}>
        <div className={classes.pcDrawer}>
          <div className={classes.main}>
            <div className={classes.marginBottom_64px} />
            <div className={classes.ill_area}>
              <div className={classes.marginBottom_64px} />
              <img
                src={ThanksIMG}
                alt="Thanks"
                className={classes.ill_image}
              />
              <div className={classes.marginBottom_64px} />
              <div className={classes.thanks_msg_bold}>
                口座の承認が完了しました。
              </div>
              <div className={classes.marginBottom_16px} />
              <div className={classes.thanks_msg_regular}>
                ツールをメールにて送信致しました。
              </div>
              <div className={classes.thanks_msg_regular}>
                迷惑メールに入っている可能性もございます。
              </div>
              <div className={classes.thanks_msg_regular}>
                ご確認の程よろしくお願い致します。
              </div>
              <div className={classes.marginBottom_64px} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ThanksLotToolTrial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThanksLotToolTrial);