import React, { useState, useEffect } from 'react';
import './HACHILotCal.css';

const HachiCalculator = () => {
  const [margin, setMargin] = useState(200000);
  const [risk, setRisk] = useState(2);
  const [includeSpread, setIncludeSpread] = useState(false);
  const [lotSize, setLotSize] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    calculateLot();
  }, [margin, risk, includeSpread]);

  const calculateLot = () => {
    const divisor = includeSpread ? 2200000 : 2000000;
    const calculatedLot = (margin / divisor) * (risk);
    // 小数点第3位を切り捨て
    const truncatedLot = Math.floor(calculatedLot * 100) / 100;
    setLotSize(truncatedLot.toFixed(2));
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('ja-JP').format(num);
  };

  const handleMarginChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 0) setMargin(value);
  };

  const handleRiskChange = (e) => {
    const value = Number(e.target.value);
    if (value >= 0 && value <= 30) setRisk(value);
  };

  return (
    <div className={`hachi-calculator ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="calculator-container">
        <div className="calculator-header">
          <h1>EA HACHI ロット計算ツール</h1>
        </div>
        
        <div className="calculator-body">
          <div className="input-section">
            <div className="input-group">
              <label htmlFor="margin">現在の証拠金</label>
              <div className="input-wrapper">
                <input
                  id="margin"
                  type="number"
                  min="10000"
                  max="10000000"
                  value={margin}
                  onChange={handleMarginChange}
                />
                <span className="input-suffix">円</span>
              </div>
              
              <div className="quick-buttons">
                {[100000, 200000, 500000, 1000000].map((val) => (
                  <button
                    key={val}
                    className={margin === val ? 'active' : ''}
                    onClick={() => setMargin(val)}
                  >
                    {formatNumber(val)}円
                  </button>
                ))}
              </div>
            </div>

            <div className="input-group">
              <label htmlFor="risk">リスク (%)</label>
              <div className="input-wrapper">
                <input
                  id="risk"
                  type="number"
                  min="0.5"
                  max="30"
                  step="0.1"
                  value={risk}
                  onChange={handleRiskChange}
                />
                <span className="input-suffix">%</span>
              </div>
              
              <div className="quick-buttons">
                {[1, 2, 5, 10, 20].map((val) => (
                  <button
                    key={val}
                    className={risk === val ? 'active' : ''}
                    onClick={() => setRisk(val)}
                  >
                    {val}%
                  </button>
                ))}
              </div>
            </div>

            <div className="toggle-group">
              <div className="toggle-label">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={includeSpread}
                    onChange={() => setIncludeSpread(!includeSpread)}
                  />
                  <span className="slider"></span>
                </label>
                <span>スプレッドを加味する</span>
              </div>
              <div className="toggle-info">
                {includeSpread ? '2pipsで計算' : '0pipsで計算'}
              </div>
            </div>
          </div>

          <div className="result-section">
            <div className="result-label">計算結果</div>
            <div className="result-value">
              {lotSize}
              <span className="result-unit">Lot</span>
            </div>
            <div className="result-info">
              HACHIのパラメーター入力画面に上記の値を入力してください
            </div>
          </div>

          <div className="formula-section">
            <div className="formula-title">計算式</div>
            <div className="formula-content">
              現在の証拠金 ÷ {includeSpread ? '2,200,000' : '2,000,000'} × リスク(%)
            </div>
            <div className="formula-example">
              例) {formatNumber(margin)}円 ÷ {includeSpread ? '2,200,000' : '2,000,000'} × {risk}% = {lotSize} Lot
            </div>
          </div>
        </div>
        
        <div className="calculator-footer">
          <button
            className="theme-toggle"
            onClick={() => setIsDarkMode(!isDarkMode)}
          >
            {isDarkMode ? '🌞 ライトモード' : '🌙 ダークモード'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HachiCalculator;