import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

//import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'


// スタイル
const styles = theme => ({
  root : {
    "& .$MuiFormHelperText-root": {
    fontFamily:'Noto Sans Japanese',
    }
  }
});


class TextBox extends React.Component {

    render() {
      const { classes} = this.props;
        //const {className,placeholder,onChange,value,name,errorMessage,required} = this.props;
        //const {className,placeholder,onChange,value,name,errorMessage,disabled,validation,maxLength} = this.props;
        const {className,placeholder,onChange,onBlur,value,name,errorMessage,disabled,validation,maxLength,sx} = this.props;
        const vali = JSON.stringify(validation);

        return (
            <TextField classes={{root: classes.root}}
             //required={required!==""}
             id={name}
             className={className}
             placeholder={placeholder}
             sx={sx}
             margin="normal"
             variant="standard"  
             onChange={onChange}
             onBlur={onBlur}  // S.Okumatsu 2020.02.07 追加
             name={name}
             value={value}
             validation={vali}
             error={errorMessage !== ""}
             disabled={disabled}
             helperText={errorMessage}
             autoComplete="off"
             inputProps={{
             style: {
             padding: '8px',
             fontFamily:'Noto Sans Japanese',
             fontWeight:'400',
             fontSize:'14px',
               },
             maxLength:maxLength,
               'data-validation': vali
             }}


            />

        );


    }
}
// Material-ui関連
TextBox.propTypes = {
    className: PropTypes.string.isRequired,
    placeholder:PropTypes.string.isRequired,
    onChange:PropTypes.func.isRequired,
    onBlur:PropTypes.func.isRequired,
    //value:PropTypes.string.isRequired,
    name:PropTypes.string.isRequired,
    errorMessage:PropTypes.string.isRequired,
    disabled:PropTypes.bool.isRequired,
    //required:PropTypes.string.isRequired,

  };

export default withStyles(styles, { withTheme: true })(TextBox);
