import React, { useState, useEffect } from 'react';
import './MailUnsubscribe.css';

const MailUnsubscribe = () => {
  const [email, setEmail] = useState('');
  const [genre, setGenre] = useState('');
  const [createrCode, setCreaterCode] = useState(0);
  const [status, setStatus] = useState('initial'); // initial, submitting, success, error
  const [errorMessage, setErrorMessage] = useState('');

  // URLからメールアドレスとジャンル、作成者コードを取得
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get('email');
    const genreParam = urlParams.get('genre');
    const createrCodeParam = urlParams.get('creater_code');
    
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }
    
    if (genreParam) {
      setGenre(decodeURIComponent(genreParam));
    }

    if (createrCodeParam) {
      setCreaterCode(parseInt(createrCodeParam) || 0);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email || !email.trim()) {
      setStatus('error');
      setErrorMessage('メールアドレスを入力してください');
      return;
    }

    setStatus('submitting');
    
    try {
      var baseUrl = window.location.origin;
      const response = await fetch(baseUrl+'/AccountMGT/server/unsubscribe.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.trim(),
          genre: genre,
          creater_code: createrCode
        })
      });

      const data = await response.json();
      if (data.success) {
        setStatus('success');
      } else {
        setStatus('error');
        setErrorMessage(data.error || '配信解除に失敗しました');
      }
    } catch (error) {
      setStatus('error');
      setErrorMessage('エラーが発生しました');
    }
  };

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-card">
        {status === 'success' ? (
          <div className="success-message">
            <div className="icon-wrapper">
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
            <h1>配信解除が完了しました</h1>
            <p>メールの配信を停止しました。またのご利用をお待ちしております。</p>
            <a 
              href={window.location.origin+"/"} 
              className="return-link"
            >
              トップページに戻る
            </a>
          </div>
        ) : (
          <>
            <h1>メール配信の解除</h1>
            <p>以下のメールアドレスの配信を解除します。</p>
            
            {status === 'error' && (
              <div className="error-message">
                {errorMessage}
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>メールアドレス</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="メールアドレスを入力"
                  disabled={status === 'submitting'}
                />
              </div>
              
              {genre && (
                <div className="genre-info">
                  <p>
                    配信カテゴリー: <span>{genre}</span>
                  </p>
                </div>
              )}
              
              <button
                type="submit"
                disabled={status === 'submitting'}
                className="unsubscribe-button"
              >
                {status === 'submitting' ? '処理中...' : 'メール配信を解除する'}
              </button>
            </form>
            
            <div className="help-text">
              <p>
                ご不明な点があれば、
                <a href="ea.dc.team@gmail.com">
                ea.dc.team@gmail.com
                </a>
                までお問い合わせください。
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MailUnsubscribe;