import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Button, Alert, Spinner, Badge, Tabs, Tab, Form } from 'react-bootstrap';
import { FaPaypal, FaArrowLeft, FaExclamationTriangle, FaInfoCircle, FaExternalLinkAlt, FaCopy, FaLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PayPalSubscriptionDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState([]);
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [webhookInfo, setWebhookInfo] = useState({
    url: '',
    copied: false
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  
  const navigate = useNavigate();

  // 管理者認証をチェック
  useEffect(() => {
    // ローカルストレージから認証状態を確認
    const authStatus = localStorage.getItem('admin_authenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  // webhookのURLを生成
  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const webhookUrl = `${protocol}//${host}/AccountMGT/server/paypal-webhook.php`;
    
    setWebhookInfo(prev => ({
      ...prev,
      url: webhookUrl
    }));
  }, []);

  // 管理者認証処理
  const handleAdminAuth = (e) => {
    e.preventDefault();
    
    // 実際の環境では、サーバーサイドでの認証処理を実装すべきです
    if (adminPassword === 'admin123') { // 本番環境では実際の認証方法に置き換える
      setIsAuthenticated(true);
      localStorage.setItem('admin_authenticated', 'true');
      setError(null);
      
      // 認証後、サブスクリプション情報を取得
      fetchSavedSubscriptions();
    } else {
      setError('管理者パスワードが正しくありません');
    }
  };
  
  // 管理者ログアウト処理
  const handleLogout = () => {
    localStorage.removeItem('admin_authenticated');
    setIsAuthenticated(false);
    navigate('/dashboard');
  };

  // 保存されたサブスクリプション情報を取得
  const fetchSavedSubscriptions = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('サブスクリプション情報の取得を開始します...');
      
      const response = await fetch('/AccountMGT/server/get-saved-subscriptions.php');
      console.log('APIレスポンスのステータス:', response.status);
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('取得したサブスクリプションデータ:', data);
      
      if (data.success) {
        // データをコンソールに出力して確認
        console.log('アクティブなサブスクリプション:', data.active_subscriptions);
        console.log('非アクティブなサブスクリプション:', data.inactive_subscriptions);
        
        setActiveSubscriptions(data.active_subscriptions || []);
        setInactiveSubscriptions(data.inactive_subscriptions || []);
        setTokenValid(true);
      } else {
        setError(data.message || 'サブスクリプション情報の取得に失敗しました');
        console.error('サブスクリプション取得エラー (APIエラー):', data.message);
      }
    } catch (err) {
      console.error('サブスクリプション取得エラー:', err);
      setError(err.message || 'サブスクリプション情報の取得中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };

  // 特定のサブスクリプションの詳細情報を取得
  const fetchSubscriptionDetail = async (subscriptionId) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`/AccountMGT/server/get-saved-subscriptions.php?id=${subscriptionId}`);
      
      if (!response.ok) {
        throw new Error(`APIエラー: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setSubscriptionDetail(data.subscription);
        setShowDetail(true);
      } else {
        setError(data.message || 'サブスクリプション詳細情報の取得に失敗しました');
      }
    } catch (err) {
      console.error('サブスクリプション詳細取得エラー:', err);
      setError(err.message || 'サブスクリプション詳細情報の取得中にエラーが発生しました');
    } finally {
      setLoading(false);
    }
  };

  // ページ読み込み時にサブスクリプション情報を取得（認証済みの場合のみ）
  useEffect(() => {
    if (isAuthenticated) {
      fetchSavedSubscriptions();
    }
  }, [isAuthenticated]);

  // WebhookのURLをクリップボードにコピーする
  const copyWebhookUrl = () => {
    navigator.clipboard.writeText(webhookInfo.url).then(() => {
      setWebhookInfo(prev => ({
        ...prev,
        copied: true
      }));
      
      // 3秒後にコピー成功表示を消す
      setTimeout(() => {
        setWebhookInfo(prev => ({
          ...prev,
          copied: false
        }));
      }, 3000);
    });
  };

  // サブスクリプションステータスに応じたバッジの色を返す
  const getStatusBadgeVariant = (status) => {
    switch (status?.toUpperCase()) {
      case 'ACTIVE':
        return 'success';
      case 'APPROVAL_PENDING':
        return 'warning';
      case 'SUSPENDED':
        return 'secondary';
      case 'CANCELLED':
        return 'danger';
      default:
        return 'info';
    }
  };

  // 日時を整形して表示
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleString();
    } catch (e) {
      return dateString;
    }
  };

  // 詳細画面から一覧に戻る
  const handleBackToList = () => {
    setShowDetail(false);
    setSubscriptionDetail(null);
  };
  
  // 管理者認証画面を表示
  if (!isAuthenticated) {
    return (
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={6}>
            <Card className="shadow-sm">
              <Card.Header as="h4" className="d-flex align-items-center bg-warning text-white">
                <FaLock className="me-2" /> 管理者専用ページ
              </Card.Header>
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                
                <Alert variant="info">
                  <FaInfoCircle className="me-2" />
                  このページは管理者専用です。PayPalサブスクリプション情報を表示します。
                </Alert>
                
                <Form onSubmit={handleAdminAuth}>
                  <Form.Group className="mb-3">
                    <Form.Label>管理者パスワード</Form.Label>
                    <Form.Control
                      type="password"
                      value={adminPassword}
                      onChange={(e) => setAdminPassword(e.target.value)}
                      placeholder="管理者パスワードを入力"
                      required
                    />
                  </Form.Group>
                  
                  <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                      認証
                    </Button>
                    <Button variant="outline-secondary" onClick={() => navigate('/dashboard')}>
                      ダッシュボードに戻る
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  // 詳細表示画面
  if (showDetail && subscriptionDetail) {
    return (
      <Container className="py-5">
        <Card className="shadow-sm mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-white">
            <div className="d-flex align-items-center">
              <FaPaypal className="me-2" />
              <h4 className="mb-0">サブスクリプション詳細 (管理者専用)</h4>
            </div>
            <Button variant="outline-light" size="sm" onClick={handleBackToList}>
              <FaArrowLeft className="me-1" /> 一覧に戻る
            </Button>
          </Card.Header>
          <Card.Body>
            <Row className="mb-4">
              <Col md={6}>
                <h5>基本情報</h5>
                <Table bordered hover responsive>
                  <tbody>
                    <tr>
                      <th>サブスクリプションID</th>
                      <td>
                        <code>{subscriptionDetail.id}</code>
                        <Button 
                          variant="link" 
                          size="sm" 
                          className="p-0 ms-2"
                          onClick={() => navigator.clipboard.writeText(subscriptionDetail.id)}
                        >
                          <FaCopy />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th>ステータス</th>
                      <td>
                        <Badge bg={getStatusBadgeVariant(subscriptionDetail.status)}>
                          {subscriptionDetail.status}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th>作成日時</th>
                      <td>{formatDate(subscriptionDetail.created_time)}</td>
                    </tr>
                    <tr>
                      <th>最終更新</th>
                      <td>{formatDate(subscriptionDetail.updated_time)}</td>
                    </tr>
                    <tr>
                      <th>プランID</th>
                      <td><code>{subscriptionDetail.plan_id}</code></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={6}>
                <h5>顧客情報</h5>
                {subscriptionDetail.subscriber ? (
                  <Table bordered hover responsive>
                    <tbody>
                      <tr>
                        <th>名前</th>
                        <td>
                          {subscriptionDetail.subscriber.name?.given_name} {subscriptionDetail.subscriber.name?.surname}
                        </td>
                      </tr>
                      <tr>
                        <th>メールアドレス</th>
                        <td>{subscriptionDetail.subscriber.email_address || '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Alert variant="warning">顧客情報がありません</Alert>
                )}
              </Col>
            </Row>
            
            <h5>イベント履歴</h5>
            {subscriptionDetail.events && subscriptionDetail.events.length > 0 ? (
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>イベント</th>
                    <th>日時</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionDetail.events.map((event, index) => (
                    <tr key={index}>
                      <td>{event}</td>
                      <td>{index === 0 ? formatDate(subscriptionDetail.created_time) : formatDate(subscriptionDetail.updated_time)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert variant="info">イベント履歴はありません</Alert>
            )}
            
            <h5>決済情報</h5>
            {subscriptionDetail.payments && subscriptionDetail.payments.length > 0 ? (
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>取引ID</th>
                    <th>金額</th>
                    <th>日時</th>
                    <th>ステータス</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionDetail.payments.map((payment, index) => (
                    <tr key={index}>
                      <td><code>{payment.id}</code></td>
                      <td>{payment.amount} {payment.currency}</td>
                      <td>{formatDate(payment.time)}</td>
                      <td>
                        <Badge bg={payment.status === 'COMPLETED' ? 'success' : 'warning'}>
                          {payment.status}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Alert variant="info">決済情報はありません</Alert>
            )}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-between">
            <Button variant="outline-secondary" size="sm" onClick={() => navigate('/dashboard')}>
              ダッシュボードに戻る
            </Button>
            <Button variant="outline-danger" size="sm" onClick={handleLogout}>
              管理者ログアウト
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="py-5">
      <Card className="shadow-sm mb-4">
        <Card.Header className="bg-dark text-white d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <FaPaypal className="me-2" />
            <h4 className="mb-0">PayPalサブスクリプション管理 (管理者専用)</h4>
          </div>
          <Button 
            variant="outline-light" 
            size="sm" 
            onClick={() => navigate('/dashboard')}
          >
            <FaArrowLeft className="me-1" /> ダッシュボード
          </Button>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              <FaExclamationTriangle className="me-2" />
              {error}
            </Alert>
          )}
          
          {tokenValid ? (
            <>
              <div className="mb-4">
                <Alert variant="info">
                  <FaInfoCircle className="me-2" />
                  <strong>Webhook URL設定:</strong> 以下のURLをPayPal Developerダッシュボードで設定してください。
                  <div className="d-flex align-items-center mt-2">
                    <code className="me-2 flex-grow-1 p-2 bg-light">{webhookInfo.url}</code>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={copyWebhookUrl}
                    >
                      {webhookInfo.copied ? '✓ コピー完了' : <><FaCopy className="me-1" /> コピー</>}
                    </Button>
                  </div>
                </Alert>
              </div>
              
              <Tabs defaultActiveKey="active" className="mb-3">
                <Tab eventKey="active" title="アクティブなサブスクリプション">
                  <div className="table-responsive">
                    {loading ? (
                      <div className="text-center py-5">
                        <Spinner animation="border" variant="primary" />
                        <p className="mt-3">サブスクリプション情報を読み込んでいます...</p>
                      </div>
                    ) : activeSubscriptions.length > 0 ? (
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>サブスクリプションID</th>
                            <th>ステータス</th>
                            <th>作成日</th>
                            <th>最終更新</th>
                            <th>顧客情報</th>
                            <th>アクション</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeSubscriptions.map(subscription => (
                            <tr key={subscription.id}>
                              <td><code>{subscription.id}</code></td>
                              <td>
                                <Badge bg={getStatusBadgeVariant(subscription.status)}>
                                  {subscription.status}
                                </Badge>
                              </td>
                              <td>{formatDate(subscription.created_time)}</td>
                              <td>{formatDate(subscription.updated_time)}</td>
                              <td>
                                {subscription.subscriber && subscription.subscriber.email_address ? (
                                  <span>{subscription.subscriber.email_address}</span>
                                ) : (
                                  <span className="text-muted">情報なし</span>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => fetchSubscriptionDetail(subscription.id)}
                                >
                                  詳細を表示
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert variant="warning">
                        アクティブなサブスクリプションはありません。
                      </Alert>
                    )}
                  </div>
                </Tab>
                <Tab eventKey="inactive" title="非アクティブなサブスクリプション">
                  <div className="table-responsive">
                    {loading ? (
                      <div className="text-center py-5">
                        <Spinner animation="border" variant="primary" />
                        <p className="mt-3">サブスクリプション情報を読み込んでいます...</p>
                      </div>
                    ) : inactiveSubscriptions.length > 0 ? (
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>サブスクリプションID</th>
                            <th>ステータス</th>
                            <th>作成日</th>
                            <th>最終更新</th>
                            <th>顧客情報</th>
                            <th>アクション</th>
                          </tr>
                        </thead>
                        <tbody>
                          {inactiveSubscriptions.map(subscription => (
                            <tr key={subscription.id}>
                              <td><code>{subscription.id}</code></td>
                              <td>
                                <Badge bg={getStatusBadgeVariant(subscription.status)}>
                                  {subscription.status}
                                </Badge>
                              </td>
                              <td>{formatDate(subscription.created_time)}</td>
                              <td>{formatDate(subscription.updated_time)}</td>
                              <td>
                                {subscription.subscriber && subscription.subscriber.email_address ? (
                                  <span>{subscription.subscriber.email_address}</span>
                                ) : (
                                  <span className="text-muted">情報なし</span>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => fetchSubscriptionDetail(subscription.id)}
                                >
                                  詳細を表示
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert variant="warning">
                        非アクティブなサブスクリプションはありません。
                      </Alert>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </>
          ) : (
            <Alert variant="warning">
              <FaExclamationTriangle className="me-2" />
              PayPal認証情報が無効または期限切れです。<a href="/paypal-dev-auth">こちら</a>から再認証してください。
            </Alert>
          )}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={() => navigate('/dashboard')}>
            ダッシュボードに戻る
          </Button>
          <Button variant="outline-danger" size="sm" onClick={handleLogout}>
            管理者ログアウト
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default PayPalSubscriptionDashboard; 