import React from "react";
import { withStyles } from '@mui/styles';
import PropTypes from "prop-types";
//import Header from "./Header";
//import Footer from "./Footer";
import Font from "../Style/font.css";
import SorryIMG from "../images/404error.png";
import Button from '@mui/material/Button';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import TextBox from './TextBox';


const styles = theme => ({

  pcDrawer: {
    //600pxより大きい場合表示
    display:'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  main:{
    width:'100%',
    marginRight:'auto',
    marginLeft:'auto',
    height:'100%',
    textAlign:'center',
  },
  area:{
    display:'inline-block',
    position:'relative',
    verticalAlign:'top',
    width:'100%',
    backgroundColor:'#0F56A8',
    marginTop:'-64px',
  },
  ill_area:{
  },

  ill_image:{
    marginLeft:'35%',
    height:'30%',
    display: 'block',
    width:'30%',
  },
  thanks_msg_bold:{
    display: 'block',
    fontSize:'28px',
    fontWeight:'700',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#333333',
  },
  thanks_msg_regular:{
    display: 'block',
    fontSize:'20px',
    textAlign:'center',
    fontFamily:'Noto Sans Japanese',
    color:'#000000',
    opacity: '0.5',
  },

  button_area:{
    width:'100%',
  },

  detail_button:{
    backgroundColor:'#0F56A8',
    color:'#ffffff',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    fontFamily:'Noto Sans Japanese',
    textAlign:'center',
  },
  top_button:{
    backgroundColor:'#ffffff',
    color:'#0F56A8',
    width:'200px',
    fontSize:'14px',
    borderColor:'#0f56A8',
    borderStyle:'none',
    textAlign:'center',
  },

  //テキストボックスの大きさ(中)
  text_width_120px:{
    width:'120px',
    margin:'0px'
  },
  text_width_150px:{
    width:'150px',
    margin:'0px'
  },
  text_width_170px:{
    width:'170px',
    margin:'0px'
  },
  text_width_280px:{
    width:'280px',
    margin:'0px'
  },
  text_width_300px:{
    width:'300px',
    margin:'0px'
  },
  //テキストボックスの大きさ(大)
  text_width_640px:{
    width:'640px',
    margin:'0px',
  },
  //テキストボックスの大きさ(中)
  text_picker_field:{
    width:'80px',
    margin:'0px',
  },
  //テキストボックスの大きさ(小)
  text_small_field:{
    width:'50px',
    margin:'0px'
  },

  //コンテンツ間の空間（横）
  marginLeft_16px:{
    marginLeft:'16px'
  },
  marginLeft_40px:{
    marginLeft:'40px'
  },
  //コンテンツ間の空間（縦）
  marginBottom_8px:{
    marginBottom:'8px',
  },
  marginBottom_16px:{
    marginBottom:'16px',
  },
  marginBottom_24px:{
    marginBottom:'24px',
  },
  marginBottom_32px:{
    marginBottom:'32px',
  },
  marginBottom_64px:{
    marginBottom:'64px',
  },
  marginBottom_96px:{
    marginBottom:'96px',
  },
  button_search:{
    fontFamily:'Noto Sans Japanese',
    fontSize:'14px',
    fontWeight:'600',
    marginTop:'12px',
    marginLeft:'12px',

  },  

  
});
const validation = {
    accountNumber: {
      required: true,
      maxLength: 255,
      halfNum:true,
    },
};
/*
  プライバシーポリシー
*/
class DeveloperPage extends React.Component {
  /////////////////////////////////////////
　//処理概要：
　//コンストラクタ、
　//
　/////////////////////////////////////////
  constructor(props){
    //thisを利用するため、かつthis.propsを定義するため
    super(props);
    this.state = {
        userValue: [],
        confirmAdd:false,
        accountResult:false,
        msg:[],
        size:{
            width:0,
            height:0,
          },        
    };
    this.handleChangeItem=this.handleChangeItem.bind(this);
    this.handleOpenConfirmAdd=this.handleOpenConfirmAdd.bind(this);
    this.handleCheckDB=this.handleCheckDB.bind(this);
    
  }

  handleChangeItem(e){
    // 入力値
    var joined = {target: e.target.name, value: e.target.value};
    var value = this.state.userValue;
    var index = value.findIndex(({target}) => target === e.target.name);
    if(index >= 0){
      value[index].value = e.target.value;
    }else{
      value.push(joined);
    }
    this.setState({userValue:value});
    console.log("this.state.userValue:",this.state.userValue);
  };  
  handleOpenConfirmAdd(){
    this.setState({
      confirmAdd: true
    });
  };

  handleCheckDB() {
    var accountnumber = this.state.userValue.find(item => item.target === "account_number").value;
    var params = new URLSearchParams();
    params.append('AccountNumber', accountnumber);
    console.log("AccountNumber:", accountnumber);
    const baseUrl = window.location.origin;
    const instance = axios.create({
      baseURL: baseUrl + "/AccountMGT/server/accountcheck_json.php"
    });
    instance.interceptors.request.use(request => {
      console.log('Starting Request: ', request);
      return request;
    });
    
    instance.interceptors.response.use(response => {
      console.log('Response: ', response);
      return response;
    });

    instance.get("", { params: params })
    .then(res => {
      if (res.data.result == "true") {
        console.log('res.data == true ');
        this.setState({
          confirmAdd: true,
          accountResult: true,
        });
      } else {
        console.log('res.data == false ');
        this.setState({
          confirmAdd: true,
          accountResult: false,
        });
      }
    })
    .catch(error => {
      console.log(error);
      // Implement redirection to error page here
    });
  }


  render() {

    const { classes } = this.props;

    const pcDrawer = (
    	<div className={classes.pcDrawer}>
        <div className={classes.main}>
        <div className={classes.marginBottom_64px} />
          <div className={classes.ill_area} >
            <div className={classes.marginBottom_64px} />
            <div className={classes.marginBottom_64px} />
            <div className={classes.thanks_msg_bold} >
              承認済口座番号の確認
            </div>
            <div className={classes.marginBottom_16px} />
            <div className={classes.thanks_msg_regular} >
            口座番号を入力すると、認証済口座番号か確認が出来ます。
            </div>
            <br/>
            <br/>
            <div className={classes.thanks_msg_regular} >
                <TextField
                id="AccountNumber"
                name="account_number"
                type="number"
                label="半角数字で入力してください"
                onChange={this.handleChangeItem}
                value={this.state.userValue.find(item => item.target === "account_number") === undefined ? '' : this.state.userValue.find(item => item.target === "account_number").value}
                />                
                <Button className={classes.button_search} variant="outlined" color="secondary" onClick={this.handleCheckDB}>
                    確認する
                </Button>
            </div>
            <br/>
            <div className={classes.button_area}>

            <br/>
          </div>           
          <div className={classes.marginBottom_16px} /> 
          <span className={classes.checkbox_label_no}>
             {this.state.confirmAdd === true && this.state.accountResult === true ? '口座番号の確認が取れました。EAのファイルの配布をお願いします。' : ''}</span>
             <span className={classes.checkbox_label_no}>
             {this.state.confirmAdd === true && this.state.accountResult === false ? '口座番号の確認が取れませんでした。お手数おかけしますが、再度口座の開設をお願いしてください。' : ''}</span>
            <div className={classes.marginBottom_64px} />
          </div>
        </div>
      </div>
    );

    return (
        <div className={Font.body}>
            {pcDrawer}
        </div>
    );
  }
}

DeveloperPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DeveloperPage);
